import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { SharedModule } from './shared/shared.module';
import { DemoPageComponent } from './pages/demo-page/demo-page.component';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { HttpClientModule } from '@angular/common/http';
import { SuccessPageComponent } from './pages/success-page/success-page.component';
import { WhitelistComponent } from './pages/whitelist/whitelist.component';
import { CoreModule } from './core/core.module';
import { ApolloModule } from 'apollo-angular';
import { ResendConfirmationModalComponent } from './pages/success-page/resend-confirmation-modal/resend-confirmation-modal.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { DemoSignupGuard } from './shared/guards/demo-signup.guard';

@NgModule({
    declarations: [
        AppComponent,
        LandingPageComponent,
        DemoPageComponent,
        ErrorPageComponent,
        SuccessPageComponent,
        WhitelistComponent,
        ResendConfirmationModalComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        SharedModule,
        RecaptchaFormsModule,
        RecaptchaModule,
        HttpClientModule,
        CoreModule,
        ApolloModule,
    ],
    providers: [
        { provide: Window, useValue: window },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        DemoSignupGuard,
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
