export const ParagraphLabels = {
    DOMAIN_LABEL: "This page tests the user's access to each domain required for the proper functioning of our Realize family of"+
                  " products. For a product to function properly, all required domains must be accessible.",
    AUTORESULT_LABEL: "The following domains are tested through the fetching of content through javascript. If a domain appears with a "+
                      " red background, it is inaccessible and needs to be white-listed for proper app functioning.",
    IMAGEDOMAIN_LABEL: "The following domains are tested through the fetching of images from the domain. If the image next to a "+
                       " domain name is broken, the domain is inaccessible and needs to be white-listed for proper app functioning.",
    MANUALDOMAIN_LABEL: "The following domains cannot be automatically tested and must, instead, be manually verified. To manually"+
                        " verify that the domain is accessible, click the \"Test Domain Access\" link next to the domain and verify "+
                        " that the target content is retrieved successfully.Because of the variety of content being fetched, "+
                        " the results may look strange.",
};

export const WHITELIST_URL_CONSTANTS ={
    PRODUCTS: [
        {
            name: 'General',
            domains: [
                'SAVVAS',
                'WWW_SAVVASREALIZE',
                'SAVVASREALIZE_',
                'READER_SAVVASREALIZE',
                'PK12LS',
                'MYSAVVASTRAINING',
                'AMAZONAWS_APPSYNC_REALTIME',
                'AMAZONAWS_APPSYNC_API',
                'LEARNINGSERVICESTECHNOLOGY',
                'CLASS_SYNC_SAVVASREALIZE',
            ]
        },
        {
            name: 'Realize',
            domains: [
                'SAVVASREALIZE',
                'K12RSDEV',
                'OPENCLASS',
                'CLOUDFLARE',
                'CLOUDFRONT',
                'REALIZEREADER',
                'REALIZEREPORTS',
                'LSTREPORTING',
                'AMAZONAWS_PROD_PRIMARY_SCO',
                'GOOGLE_ANALYTICS',
                'GOOGLE_ACCOUNTS',
                'GOOGLEUSERCONTENT',
                'GOOGLE_ANALYTICS2',
                'GOOGLE_API',
                'LIVE_NET',
                'FONTAWESOME',
                'INTERCOM',
                'INTERCOM_IO',
                'SAVVASTEXAS',
                'LABSTER',
                'TEACHINGCHANNEL',
                'APPSPOT',
                'NBCLEARN',
            ]
        },
        {
            name: 'Easybridge',
            domains: [
                'SAVVASEASYBRIDGE',
                'NOREPLY_At_K12EASYBRIDGE',
                'SAVVASSUCCESSNET',
            ]
        },
        {
            name: 'iLit',
            domains: [
                'CLASSVIEWAPPSTORE',
            ]
        },
        {
            name: 'Math XL for School',
            domains: [
                'MATHXLFORSCHOOL',
            ]
        },
        {
            name: 'Reading Spot',
            domains: [
                'READINGSPOT',
                'AMAZONAWS_PRD_STCN_READINGSPOT',
                'AMAZONAWS_PRD_PVT_READINGSPOT',
                'GOOGLETAGMANAGER',
                'GOOGLEAPIS_FONTS',
            ]
        },
        {
            name: 'Realize (Math Programs)',
            domains: [
                'XLITEMPROD_PEARSONCMG',
                'PRD_PRSN',
            ]
        },
        {
            name: 'Savvas Training',
            domains: [
                'MYSAVVASTRAINING',
                'SAVVASSUPPORT',

            ]
        },
    ],
    DOMAINS: {
        SAVVAS: {
            urlToTest: 'https://assets.savvas.com/images/slc01/savvas_lc_logo_clr_p.svg',
            domain: '*.savvas.com',
            testStrategy: 'IMAGE',
        },
        PK12LS: {
            urlToTest: 'https://sso.rumba.pk12ls.com/sso/media/doc/realize/images/terms_of_use_img.jpg',
            domain: '*.pk12ls.com',
            testStrategy: 'IMAGE',
        },
        AMAZONAWS_APPSYNC_REALTIME: {
            urlToTest: 'https://hggyurlfzbbglc4qk3agfarhqa.appsync-realtime-api.us-east-1.amazonaws.com/graphql',
            domain: '*.appsync-realtime-api.us-east-1.amazonaws.com',
            testStrategy: 'MANUAL',
            expected: 'Bad Request',
        },
        AMAZONAWS_APPSYNC_API: {
            urlToTest: 'https://hggyurlfzbbglc4qk3agfarhqa.appsync-api.us-east-1.amazonaws.com/graphql',
            domain: '*.appsync-api.us-east-1.amazonaws.com',
            testStrategy: 'MANUAL',
            expected: 'Errors: Invalid URI format',
        },
        LEARNINGSERVICESTECHNOLOGY: {
            urlToTest: 'https://help.learningservicestechnology.com/reporting/en/RealizeCustomer/Content/Resources/Images/home_dashboard_75x75.png',
            domain: '*.learningservicestechnology.com',
            testStrategy: 'IMAGE',
        },
        WWW_SAVVASREALIZE: {
            urlToTest: 'https://www.savvasrealize.com/images/background_pearson_realize.jpg',
            domain: 'www.savvasrealize.com',
            testStrategy: 'IMAGE',
        },
        SAVVASREALIZE_: {
            urlToTest: 'https://savvasrealize.com/images/logo_savvas_realize.png',
            domain: 'savvasrealize.com',
            testStrategy: 'IMAGE',
        },
        READER_SAVVASREALIZE: {
            urlToTest: 'https://reader.savvasrealize.com/assets/realizeReaderLogo.svg',
            domain: 'reader.savvasrealize.com',
            testStrategy: 'IMAGE',
        },
        CLASS_SYNC_SAVVASREALIZE: {
            urlToTest: 'https://class-sync.savvasrealize.com/assets/realize_logo@2x.png',
            domain: 'class-sync.savvasrealize.com',
            testStrategy: 'IMAGE',
        },
        SAVVASREALIZE: {
            urlToTest: 'https://www.savvasrealize.com/images/background_pearson_realize.jpg',
            domain: '*.savvasrealize.com',
            testStrategy: 'IMAGE',
        },
        K12RSDEV: {
            urlToTest: 'https://api-rr.k12rsdev.com/prod/rr-telemetry',
            domain: '*.k12rsdev.com',
            testStrategy: 'MANUAL',
            expected: 'Missing auth token message',
        },
        OPENCLASS: {
            urlToTest: 'https://goldengate.openclass.com/missionbay/img/9-dot_icons/highed/bookshelf_on.png',
            domain: '*.openclass.com',
            testStrategy: 'IMAGE',
        },
        CLOUDFLARE: {
            urlToTest: 'https://cdnjs.cloudflare.com/ajax/libs/angular-ui-bootstrap/0.10.0/ui-bootstrap-tpls.min.js',
            domain: '*.cloudflare.com',
            testStrategy: 'AUTO',
        },
        CLOUDFRONT: {
            urlToTest: 'https://db1r3xlq5ps5x.cloudfront.net/thumbnails/01_elementary_science/grade_1_science_changes_on_earth.png',
            domain: '*.cloudfront.net',
            testStrategy: 'IMAGE',
        },
        REALIZEREADER: {
            urlToTest: 'https://rr-sapi.prod.realizereader.com/sapi/oauth/token',
            domain: '*.realizereader.com',
            testStrategy: 'AUTO',
        },
        REALIZEREPORTS: {
            urlToTest: 'https://www.realizereports.com/assets/images/Realize_Reports_Logo_new.svg',
            domain: '*.realizereports.com',
            testStrategy: 'IMAGE',
        },
        LSTREPORTING: {
            urlToTest: 'https://xapi.lstreporting.com/integration/rumba/prosperocallback',
            domain: '*.lstreporting.com',
            testStrategy: 'MANUAL',
            expected: 'HTTP Status 405 - Request method GET not supported',
        },
        AMAZONAWS: {
            urlToTest: 'https://s3.amazonaws.com/savvasrealize-prelogin/index.html#/',
            domain: 's3.amazonaws.com',
            testStrategy: 'AUTO',
        },
        AMAZONAWS_PROD_PRIMARY_SCO: {
            urlToTest: 'https://s3.amazonaws.com/prod-primary-sco/1003M8RAVHL/',
            domain: 's3.amazonaws.com/prod-primary-sco',
            testStrategy: 'MANUAL',
            expected: 'Should see AccessDenied page',
        },
        GOOGLE_ANALYTICS: {
            urlToTest: 'https://analytics.google.com/',
            domain: 'analytics.google.com',
            testStrategy: 'MANUAL',
            expected: 'Should see login page',
        },
        GOOGLE_ACCOUNTS: {
            urlToTest: 'https://accounts.google.com/signup',
            domain: 'accounts.google.com',
            testStrategy: 'MANUAL',
            expected: 'Should see google sign up page',
        },
        GOOGLEUSERCONTENT: {
            urlToTest: 'https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/photo.jpg',
            domain: 'lh3.googleusercontent.com',
            testStrategy: 'IMAGE',
        },
        GOOGLE_ANALYTICS2: {
            urlToTest: 'https://google-analytics.com/about/analytics/',
            domain: 'google-analytics.com',
            testStrategy: 'MANUAL',
            expected: 'Should redirect to google marketingplatform webpage',
        },
        GOOGLE_API: {
            urlToTest: 'https://apis.google.com/js/api.js',
            domain: 'apis.google.com',
            testStrategy: 'AUTO',
        },
        LIVE_NET: {
            urlToTest: 'https://js.live.net/v7.2/OneDrive.js',
            domain: 'js.live.net',
            testStrategy: 'AUTO',
        },
        FONTAWESOME: {
            urlToTest: 'https://use.fontawesome.com/db47ff2357.js',
            domain: 'use.fontawesome.com',
            testStrategy: 'AUTO',
        },
        INTERCOM: {
            urlToTest: 'https://go.intercom.com/rs/258-CLW-344/images/hero-left%402x.png',
            domain: '*.intercom.com',
            testStrategy: 'IMAGE',
        },
        INTERCOM_IO: {
            urlToTest: 'https://blog.intercom.io/blog/wp-content/uploads/2015/03/lightbulb-rd.jpg.optimal.jpg',
            domain: '*.intercom.io',
            testStrategy: 'IMAGE',
        },
        SAVVASTEXAS: {
            urlToTest: 'https://www.savvastexas.com/images/savvas_tx_logo.png',
            domain: 'savvastexas.com',
            testStrategy: 'IMAGE',
        },
        LABSTER: {
            urlToTest: 'https://help.labster.com/assets/img/helpcenter_hero_bg_students.37feb9fc.png',
            domain: '*.labster.com',
            testStrategy: 'IMAGE',
        },
        TEACHINGCHANNEL: {
            urlToTest: 'https://www.teachingchannel.com/hubfs/Copy%20of%20Link%20in%20bio.png',
            domain: '*.teachingchannel.com',
            testStrategy: 'IMAGE',
        },
        APPSPOT: {
            urlToTest: 'https://lst-scout-prod-b.appspot.com/api/v1/rootPrograms',
            domain: 'lst-scout-prod.appspot.com',
            testStrategy: 'MANUAL',
            expected: '400 Bad request',
        },
        NBCLEARN: {
            urlToTest: 'http://correlation.nbclearn.com/cte',
            domain: '*.nbclearn.com',
            testStrategy: 'MANUAL',
            expected: 'Should redirect to https://www.youtube.com/nbclearn',
        },
        SAVVASEASYBRIDGE: {
            urlToTest: 'https://savvaseasybridge.com/wp-content/uploads/2020/05/eb_login.png',
            domain: '*.savvaseasybridge.com',
            testStrategy: 'IMAGE',
        },
        NOREPLY_At_K12EASYBRIDGE: {
            urlToTest: 'NA',
            domain: 'noreply@k12easybridge.com',
            testStrategy: 'MANUAL',
            expected: 'Required only for Teacher self reg confirmation emails. Emails come from noreply@k12easybridge.com, so any email filters need to allow this From address. Please do not click the Test Domain Access link for this one.',
        },
        SAVVASSUCCESSNET: {
            urlToTest: 'https://www.savvassuccessnet.com/images/LandingPage/savvas_logo.png',
            domain: '*.savvassuccessnet.com',
            testStrategy: 'IMAGE',
        },
        CLASSVIEWAPPSTORE: {
            urlToTest: 'http://classviewappstore.com/img/cv-logo.png',
            domain: '*.classviewappstore.com',
            testStrategy: 'MANUAL',
            expected: 'Should open the classview logo in a new tab',
        },
        MATHXLFORSCHOOL: {
            urlToTest: 'https://mathxlforschool.com/support/login/school/assets/img/logo.png',
            domain: '*.mathxlforschool.com',
            testStrategy: 'IMAGE',
        },
        GOOGLETAGMANAGER: {
            urlToTest: 'https://www.googletagmanager.com/gtm.js?id=1234&l=pearsonAnalyticsDataLayer',
            domain: 'www.googletagmanager.com',
            testStrategy: 'MANUAL',
            expected: 'Google 404 error page',
        },
       GOOGLEAPIS_FONTS: {
            urlToTest: 'https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,700|Playfair+Display',
            domain: '*.googleapis.com',
            testStrategy: 'AUTO',
        },
       READINGSPOT: {
            urlToTest: 'https://www.readingspot.com/login.htm?tenant=readingspot',
            domain: 'readingspot.com',
            testStrategy: 'MANUAL',
            expected: 'Should open readingspot homepage with two login options Easybridge and google',
        },
       AMAZONAWS_PRD_STCN_READINGSPOT: {
            urlToTest: 'https://s3.amazonaws.com/prd.stcn.readingspot.khqv96.s3-use1.prd.getmagicbox.com/static/magicbox/tresource/images/login_background_2048x1152.png?7260ae1d-a2c0-4665-ad55-a1d4aad291f9',
            domain: 'AMAZONAWS_PRD_STCN_READINGSPOT',
            testStrategy: 'IMAGE',
        },
       AMAZONAWS_PRD_PVT_READINGSPOT: {
            urlToTest: 'https://s3.amazonaws.com/prd.pvt.readingspot.145vd2.s3-use1.prd.getmagicbox.com/product_repository/magicbox/device/235375/a9a29c4480681f76ce0bde35d65f0456/235375.zip',
            domain: 'AMAZONAWS_PRD_PVT_READINGSPOT',
            testStrategy: 'MANUAL',
            expected: 'AccessDenied HTTP 403',
        },
        PRD_PRSN: {
            urlToTest: 'https://content-service.prd-prsn.com/csg/api/cm/glossary?indexId=f25e139ddd519e001da03b2865785554',
            domain: '*.prd-prsn.com',
            testStrategy: 'AUTO',
        },
        MYSAVVASTRAINING: {
            urlToTest: 'https://mysavvastraining.com/assets/img/logos/portal/a-song-of-war.jpg',
            domain: '*.mysavvastraining.com',
            testStrategy: 'IMAGE',
        },
        SAVVASSUPPORT: {
            urlToTest: 'https://support.savvas.com/support/jslibrary/1520286122000/ui-sfdc-javascript-impl/BaseTemplateCommunities.js',
            domain: 'support.savvas.com',
            testStrategy: 'MANUAL',
            expected: 'Javascript file dump',
        },
        XLITEMPROD_PEARSONCMG: {
            urlToTest: 'https://xlitemprod.pearsoncmg.com/LogIn?ReturnUrl=%2f',
            domain: 'xlitemprod.pearsoncmg.com',
            testStrategy: 'MANUAL',
            expected: 'Should see Login - Please select a method to login to the TDX Portal Site',
        },
    }
   
    }
    
