import { Component, Input, OnInit } from '@angular/core';
import { RouteConstants } from 'src/app/constants/route.constants';
import { NavigationService } from 'src/app/services/navigation.service';
import { environment } from 'src/environments/environment';
import { ButtonLabels, CardDarkConstants, ContactSupportConstants } from './card-dark.constants';

@Component({
    selector: 'card-dark',
    templateUrl: './card-dark.component.html',
    styleUrls: ['./card-dark.component.scss'],
})
export class CardDarkComponent implements OnInit {
    @Input() isDemoPage: boolean;
    demoButtonLabel: string;
    welcomeGuideButtonLabel: string;
    systemRequirementsButtonLabel: string;
    createDemoAccountButtonLabel: string;
    trainingMaterialsText: string;
    mySavvasTraining: string;
    supportArticlesText: string;
    assessmentSupport: string;
    statusCenter: string;
    contactSupportTitle: string;
    welcomeGuideUrl: string;
    systemRequirementsUrl: string;
    mySavvasTrainingUrl: string;
    assessmentSupportUrl: string;
    statusCenterUrl: string;
    learnMore: string;
    learnMoreLink: string;
    savvasUrl: string;
    linkScreenReaderText: string;
    isCanada:boolean;
    customerHandbookUrl:string;
    constructor(private navigationService: NavigationService) {}

    ngOnInit(): void {
        this.isCanada = this.navigationService.isCanadaUrl();
        this.initializeConstants();
        this.initializeUrls();
    }

    onSetupDemoButtonClick(): void {
        this.navigationService.navigateToRoute(RouteConstants.DEMO_PAGE);
    }
    onWelcomeGuideButtonClick(): void {
        if(this.isCanada){
            this.navigationService.openInNewTab(this.customerHandbookUrl);
        }
        else{
            this.navigationService.openInNewTab(this.welcomeGuideUrl);
        }
    }
    onSystemRequirementsButtonClick(): void {
        this.navigationService.openInNewTab(this.systemRequirementsUrl);
    }

    onLearnMoreLinkClick(): void {
        this.navigationService.openInNewTab(this.savvasUrl);
    }

    onTrainingMaterialsLinkClick(): void {
        this.navigationService.openInNewTab(this.mySavvasTrainingUrl);
    }

    onAssessmentSupportLinkClick(): void {
        this.navigationService.openInNewTab(this.assessmentSupportUrl);
    }

    onPlatformStatusLinkClick(): void {
        this.navigationService.openInNewTab(this.statusCenterUrl);
    }

    initializeConstants(): void {
        this.contactSupportTitle = ContactSupportConstants.TITLE;
        this.trainingMaterialsText = ContactSupportConstants.TRAINING_MATERIALS;
        this.mySavvasTraining = ContactSupportConstants.MY_SAVVAS_TRAINING;
        this.supportArticlesText = ContactSupportConstants.SUPPORT_ARTICLES;
        this.assessmentSupport = ContactSupportConstants.ASSESSMENT_SUPPORT;
        this.statusCenter = ContactSupportConstants.STATUS_CENTER;
        this.demoButtonLabel = ButtonLabels.FREE_DEMO_LABEL;
        this.welcomeGuideButtonLabel = 
            this.isCanada? ButtonLabels.CUSTOMER_HANDBOOK : ButtonLabels.WELCOME_GUIDE;
        this.systemRequirementsButtonLabel = ButtonLabels.SYSTEM_REQUIREMENTS;
        this.createDemoAccountButtonLabel = ButtonLabels.CREATE_DEMO_ACCOUNT;
        this.learnMore = ContactSupportConstants.LEARN_MORE;
        this.learnMoreLink = ContactSupportConstants.LEARN_MORE_LINK;
        this.linkScreenReaderText = CardDarkConstants.LINK_SCREEN_READER_TEXT;
    }
    initializeUrls(): void {
        this.mySavvasTrainingUrl = environment.mySavvasTrainingUrl;
        this.welcomeGuideUrl = environment.welcomeGuideUrl;
        this.assessmentSupportUrl = environment.supportPageUrl;
        this.statusCenterUrl = environment.platformStatusUrl;
        this.systemRequirementsUrl = environment.systemRequirementsUrl;
        this.savvasUrl = environment.savvasUrl;
        this.customerHandbookUrl = environment.customerHandbookUrl;
    }
    redirect(link: any): void {
        this.navigationService.openInNewTab(link);
    }
}
