export const footerConstants = {
    COPYRIGHT_YEAR: 'Copyright © ',
    SAVVAS_LEARNING_COMPANY: 'Savvas Learning Company',
    LLC: 'LLC.',
    LLC_ALL_RIGHTS_RESERVED: 'All Rights Reserved.',
    USER_AGREEMENT: 'User Agreement',
    PRIVACY_POLICY: 'Privacy Policy',
    CREDITS: ' Credits',
    LINK_SCREEN_READER_TEXT: '. To open in a new window press enter.',
    SUPPORT_NUMBER: '800-848-9500',
    SCREEN_READER_SUPPORT_NUMBER: '8 0 0. 8 4 8. 9 5 0 0',
};
