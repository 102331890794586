<main class="page-container">
  <div class="card-container">
    <div class="card-content-container">
      <cel-icon class="realize-logo" data-aria-label="Savvas Realize Logo"
        data-src="./assets/logo/logo_realize_dark.svg"></cel-icon>

      <div class="message-container">
        <div class="text-content">
          <h1 class="success-title">{{ heading }}</h1>
          <div class="instructions">
            <p class="instruction">{{ message1 }}</p>
            <p class="instruction">
              {{ message2 }}
              <cel-text-link data-label="{{ emailLink }}" (click)="resendEmail()" class="email-link"
                [attr.aria-label]="emailLink + 'To activate press enter.'"></cel-text-link>
            </p>
          </div>
        </div>
        <app-resend-confirmation-modal [showSuccessModal]="showSuccess" [showFailuareModal]="showFailure"
          (closeEvent)="closeModal()"></app-resend-confirmation-modal>
        <div class="button-container">
          <cel-button (click)="redirectToSignInUrl()" class="submit-btn"
            data-label="{{ demoAccountButtonLabel }}"></cel-button>
        </div>
      </div>
    </div>
  </div>
</main>