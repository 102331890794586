import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './components/footer/footer.component';
import { FloatCardComponent } from './components/float-card/float-card.component';
import { FloatCardModule } from './components/float-card/float-card.module';
import { CoreModule } from '../core/core.module';
import { PrivacyPolicyComponent } from './components/footer/privacy-policy/privacy-policy.component';
import { UserAgreementComponent } from './components/footer/user-agreement/user-agreement.component';

@NgModule({
    declarations: [FooterComponent, PrivacyPolicyComponent, UserAgreementComponent],
    imports: [CommonModule, FloatCardModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [FloatCardComponent, FooterComponent],
})
export class SharedModule {}
