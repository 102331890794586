import { Apollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache, ApolloLink, from } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { onError } from '@apollo/client/link/error';
import { environment } from '../../../environments/environment';

@NgModule({
    exports: [HttpClientModule],
})
export class GraphQLModule {
    constructor(private apollo: Apollo, private httpLink: HttpLink) {
        this.apollo.create({
            link: this.http(),
            cache: new InMemoryCache(),
        });
    }

    private http(): ApolloLink {
        const uri = environment.preloginServiceUrl;
        const http = this.httpLink.create({ uri });
        const decoratedHttp = from([this.onError, this.authMiddleware, http]);
        return decoratedHttp;
    }

    private readonly authMiddleware = setContext((request, { headers = {} }) => this.setHeaders(headers));

    private readonly onError = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
            graphQLErrors.forEach(({ message, locations, path }) =>
                console.error(
                    `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`
                )
            );
        }
        if (networkError) {
            console.error(`[Network error]: ${networkError}`);
        }
    });

    private readonly setHeaders = ({ headers = {} }) => ({
        headers: {
            ...headers,
        },
    });
}
