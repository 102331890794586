import { FormControl, ValidationErrors } from '@angular/forms';

export class PasswordValidator {
    static alphabetic(control: FormControl): ValidationErrors | null {
        const password = control.value;
        const regExp = /[a-zA-Z]/g;
        const isNotAlphabetic = !regExp.test(password);
        return isNotAlphabetic ? { notAlphabetic: true } : null;
    }

    static noSpaces(control: FormControl): ValidationErrors | null {
        const password = control.value;
        const regExp = /\s/;
        const containsSpace = regExp.test(password);
        return containsSpace ? { containsSpace: true } : null;
    }
}
