import { Component, OnInit } from '@angular/core';
import { ResendEmailConfirmationGQL } from 'src/app/core/graphql/generated/graphql';
import { NavigationService } from 'src/app/services/navigation.service';
import { environment } from 'src/environments/environment';
import { SuccessPageConstants } from './success-page.constants';
import { USER } from "../../shared/components/float-card/card-dark/demo-form/demo-form.constants";

@Component({
    selector: 'app-success-page',
    templateUrl: './success-page.component.html',
    styleUrls: ['./success-page.component.scss'],
})
export class SuccessPageComponent implements OnInit {
    heading: string;
    message1: string;
    message2: string;
    emailLink: string;
    userEmailAddress: string;
    demoAccountButtonLabel: string;
    userId: string;
    userName: string;
    showSuccess: boolean;
    showFailure: boolean;
    constructor(private resendEmailService: ResendEmailConfirmationGQL, private navigationService: NavigationService) {}

    ngOnInit(): void {
        this.userId = sessionStorage.getItem('userId')!;
        this.userName = sessionStorage.getItem('userName')!;
        this.heading = SuccessPageConstants.HEADING;
        this.message1 = SuccessPageConstants.DEMO_ACCOUNT_MESSAGE;
        this.message2 = SuccessPageConstants.EMAIL_MESSAGE;
        this.emailLink = SuccessPageConstants.SEND_EMAIL_MESSAGE;
        this.demoAccountButtonLabel = SuccessPageConstants.DEMO_ACCOUNT_BUTTON_LABEL;
        this.userEmailAddress = sessionStorage.getItem(USER.USER_EMAIL_ADDRESS)!;
    }
    resendEmail() {
        this.resendEmailService.mutate({ userName: this.userName, toEmail: this.userEmailAddress }).subscribe(
            (success) => {
                this.showSuccess = true;
            },
            (error) => {
                this.showFailure = true;
            }
        );
    }

    closeModal(): void {
        this.showSuccess = false;
        this.showFailure = false;
    }

    redirectToSignInUrl(): void {
        this.navigationService.redirectBrowserTo(environment.realizeLoginUrl);
    }
}
