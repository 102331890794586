import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { EventConstants } from 'src/app/constants/event.constants';
import { NavigationService } from 'src/app/services/navigation.service';
import { DemoAccountService } from 'src/app/services/demo-account.service';
import { environment } from 'src/environments/environment';
import { CardLightConstants } from './card-light.constants';

@Component({
    selector: 'card-light',
    templateUrl: './card-light.component.html',
    styleUrls: ['./card-light.component.scss'],
})
export class CardLightComponent implements OnInit {
    @Input() isDemoPage: boolean;
    displayModal: boolean;
    iconShowed: boolean;
    hasAcceptedTerms: boolean = false;
    linkClicked: boolean = false

    slogan: string;
    subheading: string;
    signInLabel: string;
    registerLabel: string;
    registerLink: string;
    signInUrl: string;
    registerUrl: string;
    setupDemoAccountTitle: string;
    setupDemoAccountInstructions: string;
    demoAccountConditions: string;
    logoImageRole: string;
    isCanada:boolean;
    hostname: string;
    siteKey: string = environment.recaptchaSiteKey;
    @ViewChild('conditionLink') conditionsLink: ElementRef;

    constructor(private navigationService: NavigationService, private demoAccountService: DemoAccountService) {
        this.demoAccountService.termsCheck$.subscribe (response => this.linkClicked= response)
    }

    ngOnInit(): void {
        this.isCanada = this.navigationService.isCanadaUrl();
        this.hostname = this.navigationService.getHostname();
        this.initializeConstants();
        this.initializeUrls();
        this.demoAccountService.termsAndConditions$.subscribe((response) => {
            this.hasAcceptedTerms = response;
        });
        this.demoAccountService.termsCheck$.subscribe ((response) => this.linkClicked= response)
        this.logoImageRole =
            window.navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ||
            window.navigator.userAgent.toLowerCase().indexOf('cros') > -1
                ? 'image'
                : 'img';
    }

    onSignInButtonClick(): void {
        this.navigationService.redirectBrowserTo(this.signInUrl);
    }

    onRegisterButtonClick(): void {
        this.navigationService.redirectBrowserTo(this.registerUrl);
    }

    openModal(): void {
        this.displayModal = true;
    }

    closeModal(event: Event): void {
        this.linkClicked = true;
        const link = this.conditionsLink?.nativeElement?.shadowRoot?.querySelector('a');
        link?.focus();

        const type = (event as CustomEvent).type;
        this.displayModal = false;
        if (type == EventConstants.TYPE_ACCEPT) {
            this.iconShowed = true;
        }
    }

    initializeConstants(): void {
        this.slogan = CardLightConstants.PRELOGIN.SLOGAN;
        this.subheading = CardLightConstants.PRELOGIN.SUBHEADING;
        this.signInLabel = CardLightConstants.PRELOGIN.SIGN_IN;
        this.registerLabel = CardLightConstants.PRELOGIN.REGISTER;
        this.setupDemoAccountTitle = CardLightConstants.DEMO.TITLE;
        this.setupDemoAccountInstructions = CardLightConstants.DEMO.INSTRUCTIONS;
        this.demoAccountConditions = CardLightConstants.DEMO.DEMO_ACCOUNT_CONDITIONS;
    }
    initializeUrls(): void {
        this.signInUrl = this.getSignInUrl();
        const TSRPageUrl = this.getTSRPageUrl();
        const ssoLoginUrl = encodeURIComponent(this.signInUrl);
        this.registerUrl = `${TSRPageUrl}?sso_login_url=${ssoLoginUrl}`;
    }

    resolved(captchaResponse: string): void {
        this.demoAccountService.setRecaptchaResponse(captchaResponse);
    }

    getSignInUrl():string {
        let signInUrl = environment.realizeLoginUrl;
        if(this.isCanada){
            signInUrl = this.hostname.includes('al2b') 
            ? environment.canadaRealizeLoginUrlal2b
            : environment.canadaRealizeLoginUrl
        }
        return signInUrl;
    }

    getTSRPageUrl():string {
        let registerUrl = environment.ebTSRPageUrl;
        if(this.isCanada){
            registerUrl = this.hostname.includes('al2b') 
            ? environment.canadaTSRPageUrlal2b
            : environment.canadaTSRPageUrl
        }
        return registerUrl;
    }
}
