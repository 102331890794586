<div class="card-light-container" [ngClass]="{'demo': isDemoPage}">
  <cel-icon
    class="realize-logo"
    data-aria-label="Savvas Realize Logo"
    data-src="./assets/logo/logo_realize_dark.svg"
    [dialogCloseIconRole]="logoImageRole"
  >
  </cel-icon>
  <div class="prelogin-container" *ngIf="!isDemoPage">
    <div class="text-content">
      <h1 class="slogan">{{ slogan }}</h1>
      <p class="subheading-mesage">
        {{ subheading }}
      </p>
    </div>
    <div class="button-group">
      <cel-button
        id="sign-in-button"
        data-label="{{ signInLabel }}"
        (click)="onSignInButtonClick()"
        [attr.data-aria-label]="signInLabel"
      >
      </cel-button>
      <cel-button
        id="register-button"
        data-variant="text"
        data-label="{{ registerLabel }}"
        (click)="onRegisterButtonClick()"
        [attr.data-aria-label]="registerLabel"
      ></cel-button>
    </div>
  </div>

  <div class="demo-container" *ngIf="isDemoPage">
    <div class="text-content">
      <h1 class="setup-account-title">{{ setupDemoAccountTitle }}</h1>
      <p class="setup-instructions">
        {{ setupDemoAccountInstructions }}
      </p>
      <div class="modal-link-container">
        <cel-icon
          class="checkmark-icon"
          data-name="checkmark-circle"
          *ngIf="iconShowed"
        ></cel-icon>
        <cel-text-link
          class="modal-link"
          [label]="demoAccountConditions"
          (click)="openModal()"
          [attr.data-aria-label]="demoAccountConditions"
          aria-haspopup="dialog"
          #conditionLink
        ></cel-text-link>
      </div>
      <div class="error-container" role="alert" aria-live="true" *ngIf="!hasAcceptedTerms && linkClicked">
        <cel-icon
          class="error-icon"
          data-name="alert-circle"
          data-size="small"
          data-color="error"
          aria-label="alert" role="img"></cel-icon>
        <span class="error-message">Please agree to the conditions</span>
      </div>

      <div class="recaptcha-container">
        <re-captcha
          siteKey="{{ siteKey }}"
          (resolved)="resolved($event)"
        ></re-captcha>
      </div>
      <app-consent-modal
        *ngIf="displayModal"
        (toggle)="closeModal($event)"
      ></app-consent-modal>
    </div>
  </div>
</div>
