<cel-modal
  class="modal-window"
  [ngClass]="{'disabled': !(agreementCert1Checked && agreementCert2Checked)}"
  [overlayCloseModal]="false"
  data-open="true"
  [showOpen]="false"
  data-header="Conditions of Demo Account Access"
  data-cancel-label="Close"
  data-accept-aria-label="Ok"
  data-cancel-aria-label="Close"
  (acceptEvent)="onAcceptButtonClick($event)"
  (cancelEvent)="onCancelButtonClick($event)"
  (closeEvent)="onCancelButtonClick($event)"
  #modal
>
  <div class="modal-body" role="document" aria-live="polite">
    <div class="text-container">
      <h2 class="agreement-text-header">{{ demoOverviewHeader }}</h2>
      <p>{{ demoOverviewText }}</p>
    </div>
    <div class="text-container">
      <h2 class="agreement-text-header">{{ demoRestrictionsHeader }}</h2>
      <p>{{ demoRestrictionsText }}</p>
    </div>
    <div class="text-container">
      <h2 class="agreement-text-header">{{ demoMisuseHeader }}</h2>
      <p>{{ demoMisuseText }}</p>
    </div>
    <div class="text-container">
      {{ demoWarning }}
    </div>
    <div class="checkbox-container">
      <cel-checkbox-item
        (celCheckboxInputChanged)="onAgreementChange($event)"
        class="checkbox-item"
        data-identifier="first"
        [label]="demoAgreementCert1"
        [truncateLength]="100"
        [checked]="hasAcceptedTerms"
      >
      </cel-checkbox-item>
      <cel-checkbox-item
        (celCheckboxInputChanged)="onAgreementChange($event)"
        class="checkbox-item"
        data-identifier="second"
        [label]="demoAgreementCert2"
        [truncateLength]="100"
        [checked]="hasAcceptedTerms"
      >
      </cel-checkbox-item>
    </div>
  </div>
</cel-modal>


