<div class="card-dark-container" [ngClass]="{'demo': isDemoPage}">
  <div class="prelogin-container" *ngIf="!isDemoPage">
    <section class="button-container">
      <div class="button-group">
        <cel-button
        *ngIf="!isCanada"
        (click)="onSetupDemoButtonClick()"
        class="btn"
        data-label="{{ demoButtonLabel }}"
        [attr.data-aria-label]="demoButtonLabel"
      ></cel-button>
      <cel-button
        (click)="onWelcomeGuideButtonClick()"
        class="btn btn-light"
        data-label="{{ welcomeGuideButtonLabel }}"
        [attr.data-aria-label]="welcomeGuideButtonLabel"
      ></cel-button>
      <cel-button
        (click)="onSystemRequirementsButtonClick()"
        class="btn btn-light"
        data-label="{{ systemRequirementsButtonLabel }}"
        [attr.data-aria-label]="systemRequirementsButtonLabel"
      ></cel-button>
      </div>
    </section>

    <div class="separation-border"></div>

    <section class="contact">
      <p class="contact-details learn-more">
          {{ learnMore }}
          <cel-text-link
            [attr.data-link-href]="savvasUrl"
            [label]="learnMoreLink"
            [attr.data-open-in-new-tab]="true"
          ></cel-text-link>
      </p>
      
      <p class="contact-details training-details">
        {{ trainingMaterialsText }}
        <cel-text-link
          [attr.data-link-href]="mySavvasTrainingUrl"
          [label]="mySavvasTraining"
          [attr.data-open-in-new-tab]="true"
        ></cel-text-link>
      </p>

      <h2 class="contact-support-title">{{ contactSupportTitle }}</h2>
      <p class="contact-details">
        <cel-text-link
          [attr.data-link-href]="assessmentSupportUrl"
          [label]="assessmentSupport"
          [attr.data-open-in-new-tab]="true"
        ></cel-text-link>
      </p>
      <p class="contact-details bottom">
        <cel-text-link
          [attr.data-link-href]="statusCenterUrl"
          [label]="statusCenter"
          [attr.data-open-in-new-tab]="true"
        ></cel-text-link>
      </p>
    </section>
  </div>
  <ng-container *ngIf="isDemoPage">
    <app-demo-form></app-demo-form>
  </ng-container>
</div>
