<cel-modal
  *ngIf="showSuccessModal"
  class="modal-window modal-success"
  [showOpen]="false"
  data-open="showSuccessModal"
  [overlayCloseModal]="false"
  data-show-cancel="false"
  data-accept-aria-label="OK"
  data-header="{{ successHeading }}"
  data-message="{{ successMessage }}"
  (celModalCloseEvent)="closeModal()"
  (celModalAcceptEvent)="closeModal()"
  #success
>
</cel-modal>

<cel-modal
  *ngIf="showFailuareModal"
  class="modal-window modal-failure"
  [showOpen]="false"
  data-open="showFailuareModal"
  [overlayCloseModal]="false"
  data-show-cancel="false"
  data-accept-aria-label="OK"
  data-header="{{ failureHeading }}"
  (celModalCloseEvent)="closeModal()"
  (celModalAcceptEvent)="closeModal()"
  #failure
>
  <div class="modal-message-container">
    <cel-icon data-name="alert-triangle" class="alert-icon"></cel-icon>
    <content>{{ failureMessage }}</content>
  </div>
</cel-modal>
