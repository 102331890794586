import { Component, EventEmitter, Input, OnInit, Output, ElementRef, AfterViewInit, ViewChild } from '@angular/core';
import { ResendModalConstants } from './resend-confirmation-modal.constant';

@Component({
    selector: 'app-resend-confirmation-modal',
    templateUrl: './resend-confirmation-modal.component.html',
    styleUrls: ['./resend-confirmation-modal.component.scss'],
})
export class ResendConfirmationModalComponent implements OnInit, AfterViewInit {
    @Output() closeEvent = new EventEmitter<Event>();
    @Input() showSuccessModal: boolean;
    @Input() showFailuareModal: boolean;
    successHeading: string;
    failureHeading: string;
    successMessage: string;
    failureMessage: string;

    @ViewChild('success') success: ElementRef;
    @ViewChild('failure') failure: ElementRef;

    constructor() {}

    ngOnInit(): void {
        console.log(this.showSuccessModal);
        this.successHeading = ResendModalConstants.SUCCESS_HEADING;
        this.successMessage = ResendModalConstants.SUCCESS_MESSAGE;
        this.failureHeading = ResendModalConstants.FAILURE_HEADING;
        this.failureMessage = ResendModalConstants.FAILURE_MESSAGE;
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            const iconButton1 = this.success?.nativeElement?.shadowRoot?.querySelector('cel-icon-button');
            const iconButton2 = this.failure?.nativeElement?.shadowRoot?.querySelector('cel-icon-button');
            iconButton1?.removeAttribute("role");
            iconButton1?.setAttribute("tabindex", "0");

            iconButton2?.removeAttribute("role");
            iconButton2?.setAttribute("tabindex", "0");

        }, 90);
    }

    closeModal(): void {
        this.closeEvent.emit();
    }
}
