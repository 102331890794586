import { Component, HostListener } from '@angular/core';
import { DemoAccountService } from './services/demo-account.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    constructor(private demoAccountService: DemoAccountService) {}
    @HostListener('window:popstate', ['$event'])
    onPopState(event: any) {
        console.log('Back button pressed');
        this.demoAccountService.setRecaptchaResponse('');
        this.demoAccountService.setTermsAndConditions(false);
    }
}
