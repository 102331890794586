import { Component, OnInit, OnDestroy } from '@angular/core';
import { WhitelistService } from './../../services/whitelist.service';
import { ParagraphLabels } from './whitelist-url.constant';
@Component({
  selector: 'app-whitelist',
  templateUrl: './whitelist.component.html',
  styleUrls: ['./whitelist.component.scss']
})
export class WhitelistComponent implements OnInit, OnDestroy {
  productResults:any;
  productName = 'General';
  domainLabel:string;
  autoResultLabel:string;
  imageDomainLabel:string;
  manualDomainLabel:string;

  constructor(public whitelist: WhitelistService) {
  }

  tabChange(productName: any) {
    this.productName = productName;
  }

  initializeConstants(): void {
    this.domainLabel =ParagraphLabels.DOMAIN_LABEL;
    this.autoResultLabel =ParagraphLabels.AUTORESULT_LABEL;
    this.imageDomainLabel = ParagraphLabels.IMAGEDOMAIN_LABEL;
    this.manualDomainLabel= ParagraphLabels.MANUALDOMAIN_LABEL;
  }

   async ngOnInit() {
    this.initializeConstants();
    this.productResults = await this.whitelist.validateThatURLsHaveBeenWhitelisted();
   }

  ngOnDestroy() {
    if(this.productResults){
    this.productResults.unsubscribe();
    }
  }
}
