<form #formRef="ngForm" [formGroup]="demoForm" method="post">
  <div class="form-container">
    <cel-text-field
      ngDefaultControl
      formControlName="email"
      class="text-field email-field"
      [ngClass]="{ 'field-error': emailError }"
      data-field-label="Your email"
      [errorMessage]="emailError ? emailErrorMessage : ''"
      data-aria-label="Your email"
      #emailElem
      (blur)="handleEmailBlur()"
    ></cel-text-field>
    <cel-text-field
      ngDefaultControl
      formControlName="password"
      class="text-field password-field"
      data-field-label="Create a password"
      data-input-type="password"
      data-toggle-password="true"
      [errorMessage]="
        passwordError ? passwordErrorMessage : ''
      "
      data-aria-label="Create a password"
      (blur)="handlePaswordBlur()"
      #pasword
    ></cel-text-field>

    <ul class="password-rules">
      <li>{{ passwordRules.CASE_SENSITIVE }}</li>
      <li>{{ passwordRules.PASSWORD_LENGTH }}</li>
      <li>{{ passwordRules.CONTAIN_LETTER }}</li>
      <li>{{ passwordRules.CONTAIN_SPECIAL_CHARACTER }}</li>
      <li>{{ passwordRules.NO_SPACES }}</li>
    </ul>

    <label class="dropdown-label" for="dropdown">
      Select where you'd like to sample products
    </label>
    <cel-single-select
      [ngClass]="{'error-border': orgError}"
      ngDefaultControl
      formControlName="orgId"
      data-primary="true"
      [menuList]="dropdownOrganizations"
      (celSingleSelect)="selectOrganization($event)"
      class="dropdown"
      (blur)="handleOrgBlur()"
      (click)="handleOrgClick()"
      data-placeholder="Select organization"
    ></cel-single-select>
    <div class="org-error">
      <div class="error-container" role="alert" aria-live="true" *ngIf="orgError">
        <cel-icon
          class="error-icon"
          data-name="alert-circle"
          data-size="small"
          data-color="error"
          aria-label="alert" role="img"></cel-icon>
        <span class="error-message">Select organization</span>
      </div>
    </div>

    <div class="create-demo-account-btn">
      <cel-button
        class="submit-btn"
        [ngClass]="{'gray-btn' : demoForm.invalid || !hasAcceptedTerms}"
        data-label="{{ createDemoAccountButtonLabel }}"
        (click)="onSubmit()"
      ></cel-button>
    </div>
  </div>
</form>
