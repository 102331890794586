import { Component,
        EventEmitter,
        OnInit,
        Output,
        ViewChild,
        ElementRef,
        HostListener,
        AfterViewInit
    } from '@angular/core';
import { EventConstants } from 'src/app/constants/event.constants';
import { DemoAccountService } from 'src/app/services/demo-account.service';
import { CardLightConstants } from '../card-light.constants';

@Component({
    selector: 'app-consent-modal',
    templateUrl: './consent-modal.component.html',
    styleUrls: ['./consent-modal.component.scss'],
})
export class ConsentModalComponent implements OnInit, AfterViewInit {
    @Output() toggle = new EventEmitter<Event>();

    agreementCert1Checked: boolean;
    agreementCert2Checked: boolean;
    hasAcceptedTerms: boolean;

    demoOverviewHeader: string;
    demoOverviewText: string;
    demoRestrictionsHeader: string;
    demoRestrictionsText: string;
    demoMisuseHeader: string;
    demoMisuseText: string;
    demoWarning: string;
    demoAgreementCert1: string;
    demoAgreementCert2: string;
    toggleOkButton: boolean = false;
    okBtnStyle: HTMLStyleElement;
    @ViewChild('modal') modal: ElementRef;
    modalOkElement: HTMLElement;
    constructor(private demoAccountService: DemoAccountService) {}

    @HostListener('keydown', ['$event'])
    private onKeydown(event: any) {
        if (event.keyCode === 13) {
           if (this.modal?.nativeElement?.shadowRoot?.activeElement?.classList.contains('ok-button')
            && !(this.agreementCert1Checked && this.agreementCert2Checked)) {
                event.preventDefault();
           }
        }
    }

    ngOnInit(): void {
        this.demoOverviewHeader = CardLightConstants.DEMO.AGREEMENT.DIALOG.BODY.OVERVIEW.HEADER;
        this.demoOverviewText = CardLightConstants.DEMO.AGREEMENT.DIALOG.BODY.OVERVIEW.TEXT;
        this.demoRestrictionsHeader = CardLightConstants.DEMO.AGREEMENT.DIALOG.BODY.RESTRICTIONS.HEADER;
        this.demoRestrictionsText = CardLightConstants.DEMO.AGREEMENT.DIALOG.BODY.RESTRICTIONS.TEXT;
        this.demoMisuseHeader = CardLightConstants.DEMO.AGREEMENT.DIALOG.BODY.MISUSE.HEADER;
        this.demoMisuseText = CardLightConstants.DEMO.AGREEMENT.DIALOG.BODY.MISUSE.TEXT;
        this.demoWarning = CardLightConstants.DEMO.AGREEMENT.DIALOG.BODY.WARNING;
        this.demoAgreementCert1 = CardLightConstants.DEMO.AGREEMENT.DIALOG.BODY.CERTIFY1;
        this.demoAgreementCert2 = CardLightConstants.DEMO.AGREEMENT.DIALOG.BODY.CERTIFY2;
        this.demoAccountService.termsAndConditions$.subscribe((response) => {
            this.hasAcceptedTerms = response;
            if (this.hasAcceptedTerms) {
                this.agreementCert1Checked = true;
                this.agreementCert2Checked = true;
            }
        });
    }

    ngAfterViewInit(): void {
        let timerId = setInterval( () => {
            const iconButton = this.modal?.nativeElement?.shadowRoot?.querySelector('cel-icon-button');
            iconButton?.removeAttribute("role");
            iconButton?.setAttribute("tabindex", "0");

            if (/Version\/13./.test(navigator.userAgent)
                && /MacIntel/.test(navigator.platform)) {
                    const contentElement = this.modal?.nativeElement?.shadowRoot?.querySelector('.body-container');
                    const contentScroll = document.createElement( 'style' );
                    contentScroll.textContent = '.context { padding-top: 17px }';
                    contentElement?.appendChild(contentScroll);
                }

            this.modalOkElement = this.modal?.nativeElement?.shadowRoot?.querySelector('.ok-button');
            this.okBtnStyle = document.createElement( 'style' );
            this.okBtnStyle.textContent = '.ok-button { pointer-events: none; }';

            if (!(this.agreementCert1Checked && this.agreementCert2Checked)) {
                this.modalOkElement?.appendChild(this.okBtnStyle);
            }

            if (iconButton) {
                iconButton.focus();
                clearInterval(timerId);
            }
        }, 60);
    }

    onAcceptButtonClick(event: Event): void {
        this.toggle.emit(event);
        if (this.agreementCert1Checked && this.agreementCert2Checked) {
            this.demoAccountService.setTermsAndConditions(true);
        }
    }
    onCancelButtonClick(event: Event): void {
        this.toggle.emit(event);
    }

    onAgreementChange(event: Event): void {
        var eventDetail = (event as CustomEvent).detail;
        if (eventDetail.identifier == EventConstants.IDENTIFIER_FIRST) {
            this.agreementCert1Checked = eventDetail.checked;
        } else {
            this.agreementCert2Checked = eventDetail.checked;
        }

        if (this.agreementCert1Checked && this.agreementCert2Checked) {
            this.modalOkElement?.removeChild(this.okBtnStyle);
        } else {
            this.modalOkElement?.appendChild(this.okBtnStyle);
        }
    }
}
