import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
import { UserResponse } from '../../models/create-demo-user.model';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
};

export type CreateDemoAccount = {
    email: Scalars['String'];
    orgId: Scalars['String'];
    password: Scalars['String'];
    recaptcha_response: Scalars['String'];
};

export type CreateDemoUser = {
    email: Scalars['String'];
    orgId: Scalars['String'];
    password: Scalars['String'];
    recaptcha_response: Scalars['String'];
};

export type Mutation = {
    __typename?: 'Mutation';
    createDemoAccount: Scalars['String'];
    resendEmailConfirmation: Scalars['Boolean'];
};


export type MutationCreateDemoAccountArgs = {
    formInput: CreateDemoAccount;
};


export type MutationResendEmailConfirmationArgs = {
    userId: Scalars['String'];
};

export type Query = {
    __typename?: 'Query';
    getSelfRegOrgs: Array<SelfRegOrganization>;
    getChildTreeForOrgId: SubOrganizations;
};

export type SelfRegOrganization = {
    __typename?: 'SelfRegOrganization';
    address1: Scalars['String'];
    address2: Scalars['String'];
    city: Scalars['String'];
    country: Scalars['String'];
    description: Scalars['String'];
    displayGroup?: Maybe<Scalars['String']>;
    displayName: Scalars['String'];
    name: Scalars['String'];
    organizationId: Scalars['String'];
    state: Scalars['String'];
    zipcode: Scalars['String'];
};

export type SubOrganizations = {
    __typename?: 'SubOrganizations';
    subOrganizations: Array<SubOrganization>;
};

export type SubOrganization = {
    __typename?: 'SubOrganization';
    address1: Scalars['String'];
    address2: Scalars['String'];
    city: Scalars['String'];
    country: Scalars['String'];
    description: Scalars['String'];
    displayGroup?: Maybe<Scalars['String']>;
    displayName: Scalars['String'];
    name: Scalars['String'];
    organizationId: Scalars['String'];
    state: Scalars['String'];
    zipcode: Scalars['String'];
};

export type CreateDemoAccountMutationVariables = Exact<{
    formInput: CreateDemoAccount;
}>;

export type CreateDemoUserMutationVariables = Exact<{
    userDetails: CreateDemoUser;
}>;


export type CreateDemoAccountMutation = { __typename?: 'Mutation', createDemoAccount: string };

export type CreateDemoUserMutation = { __typename?: 'Mutation', createDemoUser: UserResponse };

export type ResendEmailConfirmationMutationVariables = Exact<{
    userName: Scalars['String'];
    toEmail: Scalars['String'];
}>;


export type ResendEmailConfirmationMutation = { __typename?: 'Mutation', resendEmailConfirmation: boolean };

export type GetSelfRegOrgsQueryVariables = Exact<{ [key: string]: never; }>;

export type GetChildTreeForOrgIdQueryVariables = Exact<{ [key: string]: any; }>;

export type GetSelfRegOrgsQuery = {
    __typename?: 'Query',
    getSelfRegOrgs: Array<{
        __typename?: 'SelfRegOrganization',
        displayName: string,
        organizationId: string,
        country: string
    }>
};

export type GetChildTreeForOrgIdQuery = {
    __typename?: 'Query',
    getChildTreeForOrgId: {
        subOrganizations: Array <{
            __typename?: 'SubOrganizations',
            displayName: string,
            organizationId: string,
            country: string
        }>
    }
};

export const CreateDemoAccountDocument = gql`
    mutation createDemoAccount($formInput: CreateDemoAccount!) {
        createDemoAccount(formInput: $formInput)
    }
`;

@Injectable({
    providedIn: 'root'
})
export class CreateDemoAccountGQL extends Apollo.Mutation<CreateDemoAccountMutation, CreateDemoAccountMutationVariables> {
    document = CreateDemoAccountDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}

export const ResendEmailConfirmationDocument = gql`
    mutation resendEmailConfirmation($userName: String!, $toEmail: String!) {
        resendEmailConfirmation(userName: $userName, toEmail: $toEmail)
    }
`;

@Injectable({
    providedIn: 'root'
})
export class ResendEmailConfirmationGQL extends Apollo.Mutation<ResendEmailConfirmationMutation, ResendEmailConfirmationMutationVariables> {
    document = ResendEmailConfirmationDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}

export const GetSelfRegOrgsDocument = gql`
    query getSelfRegOrgs {
        getSelfRegOrgs {
            displayName
            organizationId
            country
        }
    }
`;

@Injectable({
    providedIn: 'root'
})
export class GetSelfRegOrgsGQL extends Apollo.Query<GetSelfRegOrgsQuery, GetSelfRegOrgsQueryVariables> {
    document = GetSelfRegOrgsDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}

export const GetChildTreeForOrgIdDocument = gql`
    query getChildTreeForOrgId($orgId: String!) {
        getChildTreeForOrgId(orgId: $orgId) {
            subOrganizations {
                displayName,
                country,
                organizationId
            }
        }
    }`;

@Injectable({
    providedIn: 'root'
})
export class GetChildTreeForOrgIdGQL extends Apollo.Query<GetChildTreeForOrgIdQuery, GetChildTreeForOrgIdQueryVariables> {
    document = GetChildTreeForOrgIdDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}

export const CreateDemoUserDocument = gql`
    mutation CreateDemoUser($userDetails: UserDetailsInput!) {
        createDemoUser(userDetails: $userDetails) {
            userId
            userName
            responseStatus
            messages {
                validationId
                type
                message
                rollBackServiceCode
            }
        }
    }`;

@Injectable({
    providedIn: 'root'
})
export class CreateDemoUserGQL extends Apollo.Mutation<CreateDemoUserMutation, CreateDemoUserMutationVariables> {
    document = CreateDemoUserDocument;

    constructor(apollo: Apollo.Apollo) {
        super(apollo);
    }
}
