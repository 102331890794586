import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DemoAccountService {
    private termsAndConditions = new BehaviorSubject<boolean>(false);
    termsAndConditions$ = this.termsAndConditions.asObservable();
    private recaptchaResponse = new BehaviorSubject<string>('');
    recaptchaResponse$ = this.recaptchaResponse.asObservable();
    private termsCheck = new BehaviorSubject<boolean>(false)
    termsCheck$= this.termsCheck.asObservable()
    constructor() {}

    setRecaptchaResponse(response: string) {
        this.recaptchaResponse.next(response);
    }
    setTermsAndConditions(response: boolean) {
        this.termsAndConditions.next(response);
    }
    setTermsCheck(response:boolean){
        this.termsCheck.next(response)
    }

    hasSignedUp(): boolean {
        return !!sessionStorage.getItem('userId');
    }
}
