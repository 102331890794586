import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { RouteConstants } from 'src/app/constants/route.constants';
import { DemoAccountService } from 'src/app/services/demo-account.service';
import { NavigationService } from 'src/app/services/navigation.service';

@Injectable({
    providedIn: 'root',
})
export class DemoSignupGuard implements CanActivate {
    constructor(private navigationService: NavigationService, private demoAccountService: DemoAccountService) {}

    canActivate(): boolean {
        if (this.demoAccountService.hasSignedUp()) {
            return true;
        } else {
            this.navigationService.navigateToRoute(RouteConstants.HOME);
            return false;
        }
    }
}
