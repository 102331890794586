<div class="error-message-container">
  <cel-error-message
    [errorMessage]="errorMessage"
    [directions]="directions"
    [hasErrorCode]="false"
  ></cel-error-message>
  <cel-text-link
    (click)="navigateToDemoPage()"
    class="demo-link"
    [label]="demoAccountConditions"
  >
  </cel-text-link>
</div>
