import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { RouteConstants } from 'src/app/constants/route.constants';
import { DemoAccountService } from 'src/app/services/demo-account.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { ErrorPageConstants } from './error-page.constants';

@Component({
    selector: 'app-error-page',
    templateUrl: './error-page.component.html',
    styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent implements OnInit, OnDestroy {
    demoAccountConditions: string;
    showErrorCode: boolean = false;
    errorMessage: string;
    directions: string;
    constructor(private demoAccountService: DemoAccountService, private navigationService: NavigationService) {}

    ngOnInit(): void {
        this.demoAccountConditions = ErrorPageConstants.CREATE_DEMO_ACCOUNT_LINK;
        this.errorMessage = ErrorPageConstants.ERROR_MESSAGE;
        this.directions = ErrorPageConstants.DIRECTIONS;
    }
    ngOnDestroy(): void {}
    navigateToDemoPage(): void {
        this.demoAccountService.setRecaptchaResponse('');
        this.demoAccountService.setTermsAndConditions(false);
        this.navigationService.navigateToRoute(RouteConstants.DEMO_PAGE);
    }
}
