import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { RouteConstants } from 'src/app/constants/route.constants';
import { NavigationService } from 'src/app/services/navigation.service';
import { environment } from 'src/environments/environment';
import { footerConstants } from './footer.constants';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
    savvasUrl: string;
    userAgreementUrl: string;
    privacyPolicyUrl: string;
    testUserAgreementUrl = '/#/userAgreement';
    testPrivacyPolicyUrl = '/#/privacy';
    creditsUrl: string;
    copyrightText: string;
    savvasLearningCompany: string;
    allRightsReserved: string;
    userAgreement: string;
    privacyPolicy: string;
    credits: string;
    llc: string;
    errorPage: boolean;
    demoPage: boolean;
    footerPage: boolean;
    linkScreenReaderText: string;
    currentYear: number = new Date().getFullYear();
    constructor(private navigationService: NavigationService, private router: Router) {}

    ngOnInit(): void {
        this.initializeConstants();
        this.initializeUrls();
        this.toggleFooter();
    }

    onCopyrightLinkClick() :void {
        this.navigationService.openInNewTab(this.savvasUrl);
    }

    onUserAgreementLinkClick() :void {
        this.navigationService.navigateToRoute(RouteConstants.USERAGREEMENT_PAGE);
    }

    onPrivacyPolicyLinkClick() :void {
        this.navigationService.navigateToRoute(RouteConstants.PRIVACY_PAGE);
    }

    onCreditsLinkClick() :void {
        this.navigationService.openInNewTab(this.creditsUrl);
    }

    initializeConstants() {
        this.copyrightText = footerConstants.COPYRIGHT_YEAR + this.currentYear;
        this.savvasLearningCompany = footerConstants.SAVVAS_LEARNING_COMPANY;
        this.allRightsReserved = footerConstants.LLC_ALL_RIGHTS_RESERVED;
        this.userAgreement = footerConstants.USER_AGREEMENT;
        this.privacyPolicy = footerConstants.PRIVACY_POLICY;
        this.credits = footerConstants.CREDITS;
        this.llc = footerConstants.LLC;
        this.linkScreenReaderText = footerConstants.LINK_SCREEN_READER_TEXT;
    }

    initializeUrls() {
        this.savvasUrl = environment.savvasUrl;
        this.userAgreementUrl = environment.userAgreementUrl;
        this.privacyPolicyUrl = environment.privacyPolicyUrl;
        this.creditsUrl = environment.creditsUrl;
    }

    toggleFooter(): void {
        this.router.events.subscribe((e) => {
            if (e instanceof NavigationStart) {
                this.errorPage = e.url === '/' + RouteConstants.ERROR_PAGE ? true : false;
                this.demoPage = e.url === '/' + RouteConstants.DEMO_PAGE ? true : false;
                this.footerPage =
                    e.url === '/privacy/corporate/privacy/learning-services-privacy-policy.html'
                    || e.url === '/privacy'
                    || e.url === '/userAgreement.html'
                    || e.url === '/userAgreement';
            }
        });
    }

    redirect(link: any): void {
        this.navigationService.openInNewTab(link);
    }
}
