import { ViewportScroller } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { footerConstants } from '../footer.constants';

@Component({
    selector: 'app-privacy-policy',
    templateUrl: './privacy-policy.component.html',
    styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent implements OnInit {
    isShow: boolean;
    topPosToStartShowing = 100;
    supportNumber: string;
    screenReaderSupportNumber: string;
    FAQURL='https://www.ftc.gov/business-guidance/resources/complying-coppa-frequently-asked-questions';
    iKeepSafeURL='https://ikeepsafe.org/certification/coppa/'
    currentYear: number = new Date().getFullYear();
    constructor(private viewportScroller: ViewportScroller) {}

    ngOnInit(): void {
        this.supportNumber = footerConstants.SUPPORT_NUMBER;
        this.screenReaderSupportNumber = footerConstants.SCREEN_READER_SUPPORT_NUMBER;
    }

    @HostListener('window:scroll')
    checkScroll() {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scrollPosition >= this.topPosToStartShowing) {
            this.isShow = true;
        } else {
            this.isShow = false;
        }
    }

    gotoTop() {
        window.scroll({
            top: 0,
            left: 0,
        });
    }

    onClickScroll(elementId: string) {
        this.viewportScroller.scrollToAnchor(elementId);
    }
}
