<main role="main">
  <div class="container-fluid g-0">
    <div class="row">
      <div class="col-sm-8 col-xs-12">
        <h1 class="title">Privacy Policy</h1>
        <div class="section text">
          <h2 class="no-bg title-align-left subtitle">
            This privacy policy is regularly reviewed and updated as necessary
          </h2>
          <h3 class="para-title">Recent updates include:</h3>
          <ul>
            <li>
              Updating our company name to reflect changes within our
              organization.
            </li>
            <li>
              Describing of new rights available to California consumers under
              the California Privacy Rights Act (CPRA).
            </li>
            <li>
              Clarifying the use of external content and our evaluation of
              third-party content providers.
            </li>
            <li>
              Updated information on our iKeepSafe COPPA Safe Harbor
              Certification Program.
            </li>
            <li>
              Affirming our dedication to Student Data Privacy.
            </li>
            <li>
              Affirming our adherence to international data protection standards.
            </li>
          </ul>
        </div>
        <div class="section text">
          <h4 class="toc-header">Table of Contents</h4>
          <ol class="toc-list" type="A">
            <li>
              <a (click)="onClickScroll('policyOverview')"
              href="/#/privacy"
              >Policy Overview</a>
            </li>
            <li><a (click)="onClickScroll('contactUs')"
              href="/#/privacy"
              >Contact Us</a></li>
            <li>
              <a (click)="onClickScroll('personalInfoWeCollect')"
              href="/#/privacy"
                >Personal Information We Collect</a
              >
            </li>
            <li>
              <a (click)="onClickScroll('howWeUsePersonalInfo')"
              href="/#/privacy"
                >How We Use Personal Information</a
              >
            </li>
            <li>
              <a (click)="onClickScroll('howWeSharePersonalInfo')"
              href="/#/privacy"
                >How We Share Personal Information</a
              >
            </li>
            <li>
              <a (click)="onClickScroll('security')"
              href="/#/privacy">Security</a>
            </li>
            <li>
              <a (click)="onClickScroll('schoolCustResponsibilities')"
              href="/#/privacy"
                >School Customer Responsibilities</a
              >
            </li>
            <li>
              <a (click)="onClickScroll('miscellaneous')"
              href="/#/privacy">Miscellaneous</a>
            </li>
            <li>
              <a (click)="onClickScroll('definitions')"
              href="/#/privacy">Definitions</a>
            </li>
            <li>
              <a (click)="onClickScroll('californiaConsumerProtectionAct')"
              href="/#/privacy"
                >California Consumer Protection Act</a
              >
            </li>
          </ol>
        </div>
        <div class="section text">
          <h2 class="section-header">
            <a name="policyOverview"></a>A. Policy Overview
          </h2>

          <p class="p-text">
            This privacy policy (&quot;Privacy Policy&quot; or
            &quot;Policy&quot;) describes how Savvas Learning Company LLC, and its subsidiaries (the
            &quot;Company,&quot; &quot;we,&quot; &quot;our&quot; or
            &quot;us&quot;), collects, protects, uses and shares personal
            information gathered from Users of our websites, applications,
            products and services (&quot;Educational Service(s)&quot;, or
            &quot;Service(s)&quot;) that link to this Privacy Policy. It is
            important to us that educational institutions or individuals who
            have purchased and are using our Educational Services
            (&quot;Customers&quot;, or &quot;you&quot;) understand the measures
            we have taken to keep our Services a safe and trustworthy
            environment for all Users.
          </p>

          <p class="p-text">
            Although Sections C, D, and E of this Privacy Policy provide
            detailed information on our collection, use and disclosure of your
            Personal Information, we would like to highlight the following:
          </p>

          <!-- prettier-ignore -->
          <ul>
            <li>We will NEVER sell your Student Data to third parties.</li>
            <li>We will NEVER perform targeted advertising of your Student Users.</li>
            <li>We will NEVER share your Student Data with third parties for the purpose of targeted advertising.</li>
            <li>We will NEVER utilize cross-site tracking technology on our sites for Student Users.</li>
            <li>We will NEVER build marketing profiles of your Student Users.</li>
            <li>We will NEVER claim ownership of your Student Data.</li>
          </ul>

          <h3 class="para-title">Changes to this Policy</h3>

          <p class="p-text">
            We encourage you to read through and understand this Privacy Policy.
            From time to time, we may update it to address new issues or reflect
            changes to our Services. If we are making updates that involve
            material changes to the collection, protection, use or disclosure of
            Personal Information, we will attempt to provide you with advanced
            notice of the revisions. This notice may occur through various
            methods depending on which will best allow us to reach affected
            customers. These methods may include, but are not limited to,
            e-mail, postal mail, or a conspicuously-posted website notice.
            Depending on the method that is used, we may also provide Users of
            the Service with advance notice of material changes, however,
            Customers who are educational institutions should ensure that they
            keep students, parents, and other stakeholders informed of any
            material changes, as data handling practices can vary based on
            school-specific configurations and requests. Please feel free to
            contact us if you have questions or concerns regarding intended
            Privacy Policy revisions.
          </p>

          <p class="p-text">
            In accordance with our commitment to provide notice as described
            above, we reserve the right to revise the terms of this Privacy
            Policy. Any such revisions to this Policy are effective immediately
            upon posting of a revised Policy. Your use of the Services
            subsequent to such posting constitutes your acceptance of such
            revisions.
          </p>
        </div>

        <div class="color-default text-default parbase section text">
          <h2 class="section-header"><a name="contactUs"></a>B. Contact Us</h2>

          <p class="p-text">
            We are committed to maintaining a dialogue with Customers about our
            data handling practices. If you have any concerns, comments or
            questions about this Policy or general Service-related data handling
            practices, please contact us using one of the following methods:
          </p><br />

          <h3 class="h3-text">Email:</h3>
          <p class="p-text">
            privacy@savvas.com
          </p><br />

          <h3 class="h3-text">Mailing address:</h3>
          <p class="p-text">
            Savvas Learning Company LLC<br />
            Attention: Chief Privacy Officer<br />
            15 E Midland Ave, Paramus<br />
            New Jersey 07652, US
          </p><br />

          <h3 class="h3-text">Phone:</h3>
          <p class="p-text">
            <span [attr.aria-label]="screenReaderSupportNumber"><span aria-hidden="true">{{supportNumber}}</span></span>
          </p>
        </div>
        <div class="color-default text-default parbase section text">
          <h2 class="section-header">
            <a name="personalInfoWeCollect"></a>C. Personal Information We
            Collect
          </h2>

          <p class="p-text">
            We collect information about Users of the Service in multiple ways,
            including Personal Information provided directly to us by a Customer
            for upload to the Service, data collected directly from or generated
            by Student and Educator Users of the Service, and data generated
            through your use of the Service. Depending on the Services provided,
            we may also collect Personal Information through other methods that
            follow the terms of this Privacy Policy.
          </p>

          <h3 class="para-title">Personal Information Input by Customers</h3>

          <p class="p-text">
            When a Customer contracts with us to provide an Educational Service,
            initial setup and configuration of that Service, in most cases, will
            involve an initial transfer of Personal Information by the Customer.
            In addition, some of our products allow our customers to input
            certain optional demographic or other identifying data (student
            email address, date of birth, etc.) if such information is useful
            for the district&#39;s educational purposes. These fields do not
            need to be populated to use our products, but if the district
            supplies any of this data, it will be treated in accordance with
            this Policy. The following tables contain information about elements
            of Student and Educator Data commonly collected from our Customers
            (as applicable). Although we do attempt to maintain the accuracy of
            these tables, data handling practices may vary significantly across
            our K-12 products.
          </p>
        </div>
        <div class="no-bg section title title-align-left m30">
          <h3 class="para-title">Student data input by Customers</h3>
        </div>
        <section
          class="column-control no-border section default-background layout-default pull-up-20"
        >
          <div>
            <div
              class="no-bg margin-default section title title-align-left m20"
            >
              <h4 class="para-subtitle">Required Student Data</h4>
            </div>
            <div class="color-default text-default parbase section text">
              <ul>
                <li>First and Last Name</li>
                <li>School Name</li>
                <li>District/Institutional Name</li>
                <li>Grade, Year, or Group</li>
              </ul>
            </div>
          </div>
        </section>
        <div class="m30">
          <h3 class="para-title">Educator data input by Customers</h3>
        </div>
        <section
          class="column-control no-border section default-background layout-default pull-up-20"
        >
          <div>
            <div class="m20">
              <h4 class="para-subtitle">Required Educator Data</h4>
            </div>
            <div class="color-default text-default parbase section text">
              <ul>
                <li>First and Last Name</li>
                <!-- prettier-ignore -->
                <li>Contact Details (School/District-Issued or Personal E-mail Address)
                </li>
                <li>School Name</li>
                <li>District/Institutional Name</li>
              </ul>
            </div>
          </div>
          <div>
            <div class="m20">
              <h4 class="para-subtitle">Optional Educator Data</h4>
            </div>
            <div class="color-default text-default parbase section text">
              <ul>
                <li>District ID</li>
                <li>Date of Birth</li>
              </ul>
            </div>
          </div>
        </section>
        <div class="color-default text-default parbase section text">
          <h3 class="para-title">
            Directly Collected or User Generated Personal Information
          </h3>

          <p class="p-text">
            Many of our Educational Services allow Users to create, upload and
            share information. Collection of this information is optional and at
            the discretion of the Customer and/or User. The following tables
            contain information about elements of Student and Educator Data
            commonly collected directly from or generated by our Customers and
            their Users.
          </p>
        </div>
        <div class="m30">
          <h3 class="para-title">Optional data collected from end users</h3>
        </div>
        <section
          class="column-control no-border section default-background layout-default pull-up-20"
        >
          <div class="m20">
            <h4 class="para-subtitle">
              Student Data Input or Generated by Users
            </h4>
          </div>
          <div class="color-default text-default parbase section text">
            <ul>
              <li>Grades</li>
              <li>Exercise, Activity, or Assessment Responses</li>
              <li>
                Exercise, Activity, and Assessment Grades, Scores, and Reports
              </li>
              <li>Educator Feedback</li>
              <li>Assessment or Appraisal Information or Reports</li>
              <li>Creative Works</li>
              <li>Notebook Entries or Text Annotations</li>
              <li>Private Forum or Chat Postings or Comments</li>
              <li>Photographic, Video, or Audio Recordings</li>
            </ul>
          </div>
          <div class="educator-data-title">
            <h4 class="para-subtitle">
              Educator Data Input or Generated by Users
            </h4>
          </div>
          <div class="color-default text-default parbase section text">
            <ul>
              <li>Private Forum or Chat Postings or Comments</li>
              <li>
                Contact Details (School-Issued or Personal E-mail Address)
              </li>
              <li>Photographic, Video, or Audio Recordings</li>
            </ul>
          </div>
        </section>
        <div class="color-default text-default parbase section text">
          <h3 class="para-title">Service-Generated Data</h3>

          <p class="p-text">
            The Service you have purchased may generate information tied to
            specific Users as a core part of its functionality, such as for
            adaptive learning or providing immediate feedback to students.
            Service-generated data may also include the automatic generation of
            usernames or other data elements tied to a specific User, where
            those elements are not provided or set by the Customer.
          </p>

          <h3 class="para-title">Cookies and Related Technologies</h3>

          <p class="p-text">
            Our Services use cookies to enable you to sign-in to the Service and
            access your stored preferences and settings. You have a variety of
            tools to control the use of cookies, web beacons, and similar
            technologies, including browser controls to block and delete
            cookies. If you choose to disable or block these technologies, it
            may prevent or impair required functionality and therefore your use
            of the Service.
          </p>

          <h3 class="para-title">Application and System Logs</h3>

          <p class="p-text">
            Application and system logs are critical to ensuring the
            availability and security of the Service. We collect log data for
            such purposes as monitoring the health of the Service, detecting
            unauthorized access and fraudulent activity on the Service,
            preventing and responding to Service-related security incidents, and
            ensuring appropriate scaling of the Service&#39;s computing
            resources.
          </p>

          <h3 class="para-title">Do Not Track Disclosures</h3>

          <p class="p-text">
            Do Not Track (&quot;DNT&quot;) is a proposed mechanism for allowing
            website visitors to control the collection of certain Usage Data.
            Although there has been research into the development of a standard
            to support the use of DNT signals, there is no adopted standard to
            follow. We do not currently respond to Do Not Track signals, but we
            are closely monitoring DNT proposals for further developments.
          </p>

          <h3 class="para-title">Third-Party Collection</h3>

          <p class="p-text">
            Although we may use third-party vendors, subcontractors, and service
            providers to assist us in providing the Service, we do not permit
            third-party ad networks or similar services to collect the Personal
            Information of our authenticated Users.
          </p>
        </div>
        <div class="color-default text-default parbase section text">
          <h2 class="section-header">
            <a name="howWeUsePersonalInfo"></a>D. How We Use Personal
            Information
          </h2>

          <p class="p-text">
            We use your Personal Information for educational purposes and to
            exercise our legal rights, as described below.
          </p>

          <h3 class="para-title">Educational Uses</h3>

          <p class="p-text">
            We use Personal Information to provide you with the Service(s)
            requested. We may use your Personal Information for any purposes
            required or permitted under our Agreement or with your consent.
          </p>

          <h3 class="para-title">Legal and Safety Uses</h3>

          <p class="p-text">
            We may use your Personal Information to protect or exercise our
            legal rights, to defend against claims, to investigate fraud and
            other criminal conduct, to enforce our Terms of Service, to respond
            to a government request, to protect the security, integrity and
            availability of the Service, or to otherwise protect the property
            and safety of the Company, our Users, and others.
          </p>

          <h3 class="para-title">De-Identified and Aggregate Data</h3>

          <p class="p-text">
            Customer data no longer needed for the delivery of our services
            shall either be deleted or de-identified and aggregated. We may use
            Usage Data and other properly de-identified or aggregate data to
            improve existing products, develop new products, communicate product
            effectiveness and outcomes, and for other related purposes. Our
            methods for de-identification are informed by guidance from the
            National Institute of Standards and Technology (NIST), the U.S.
            Department of Education's Privacy Technical Assistance Center, and
            the Department of Health and Human Services. Unless required to do
            so by law, we will not attempt to re-identify de-identified data
            and, where feasible and appropriate, will not transfer de-identified
            data to a third party unless they also agree not to attempt
            re-identification.
          </p>
        </div>
        <div class="color-default text-default parbase section text">
          <h2 class="section-header">
            <a name="howWeSharePersonalInfo"></a>E. How We Share Personal
            Information
          </h2>

          <p class="p-text">
            We share your Personal Information with third parties solely for the
            purpose of providing Educational Services to you and to exercise our
            legal rights, as described below.
          </p>

          <h3 class="para-title">Subcontractors</h3>

          <p class="p-text">
            Depending on the Service, we may hire subcontractors, vendors or
            other third parties to help deliver or improve the Service.
            Third-parties that we work with who have access to your Personal
            Information are subject to stringent privacy and security
            contractual requirements equivalent to those set forth in this
            Policy, including, but not limited to, deletion, de-identification
            and prohibitions on collection, use or disclosure of Personal
            Information for non-educational purposes and maintenance of a
            comprehensive information security program.
          </p>

          <h3 class="para-title">Merger, Acquisition or Bankruptcy</h3>

          <p class="p-text">
            If we are involved in a merger, acquisition, or sale of all or a
            portion of our assets, we may need to share your Personal
            Information with the acquiring entity. We will condition any merger,
            acquisition, or sale on continued adherence to the terms of this
            Policy and maintaining a materially similar level of protection for
            your Personal Information. If such an event occurs, we will provide
            you information about the coming change, how it may impact you and
            any choices you may have.
          </p>

          <h3 class="para-title">Legal and Safety Disclosures</h3>

          <p class="p-text">
            We may disclose your Personal Information when it is required or
            permitted by law, such as to comply with a subpoena, court order or
            similar legal process, to protect or exercise our legal rights, to
            defend against claims, to investigate fraud and other criminal
            conduct, to enforce our Terms of Service, to respond to a government
            request, to protect the security, integrity and availability of the
            Service, or to otherwise protect the property and safety of the
            Company, our Users, and others.
          </p>

          <h3 class="para-title">Data Ownership and Access Requests</h3>

          <p class="p-text">
            Customers who are educational institutions have primary
            responsibility for fulfilling student and parent access, amendment,
            and export requests. In most cases, Customers can fulfill these
            requests using the built-in functionality of the Service. Where this
            functionality is not available or the Customer cannot otherwise
            fulfill the request on their own, we will provide reasonable
            assistance with the production or export of Student Data if the
            assistance is in accordance with our Agreement and applicable law.
            In rare cases, we may not be able to fully satisfy these requests.
            Examples include requests for confidential company information in
            addition to Student Data, requests for Student Data in a specific or
            proprietary format that we are unable to support, or requests that
            are prohibited by law.
          </p>
        </div>
        <div class="color-default text-default parbase section text">
          <h2 class="section-header"><a name="security"></a>F. Security</h2>

          <p class="p-text">
            We store and process Customer Personal Information in accordance
            with industry standards and applicable law. Our comprehensive
            information security program protects your Personal Information from
            unauthorized access, use and disclosure through the use of
            reasonable and appropriate physical, administrative and technical
            safeguards. We perform periodic risk assessments of our information
            security program and prioritize remediation of identified security
            vulnerabilities. Nevertheless, security is a shared responsibility
            and no method of data transmission over the Internet or method of
            electronic storage is 100% secure. Therefore, we cannot guarantee
            the absolute security of your Personal Information.
          </p>

          <p class="p-text">
            If you have general questions for us regarding the security and
            confidentiality of your Personal Information, please feel free to
            contact us using the information in Section B above.
          </p>

          <h3 class="para-title">Breach Notification</h3>

          <p class="p-text">
            In the event of a security incident affecting our systems that
            involves your Personal Information, we will notify you as required
            by applicable law and the terms of our Agreement. We will always
            attempt to notify you of any security incident affecting your
            Personal Information that we believe poses a material risk of harm
            to you, your staff or your students.
          </p>

          <h3 class="para-title">Federation and Identity Management Support</h3>

          <p class="p-text">
            We strongly support and encourage the use of secure federated
            identity management technologies such as SAML in conjunction with
            our Services. These technologies make access to our Service safer
            and more secure for your district and Users.
          </p>

          <h3 class="para-title">Use, Retention and Deletion</h3>

          <p class="p-text">
            We do not collect, maintain, use, or share Personal Information
            beyond what is required for the educational purposes for which it
            was collected and will retain (where required), destroy or
            de-identify your Personal Information in accordance with applicable
            law and the terms of our Agreement. Deletion and retention
            functionality and procedures vary based on the Service used.
          </p>
          <div class="color-default text-default parbase section text">
            <h2 class="section-header">
              <a name="schoolCustResponsibilities"></a>G. Customer
              Responsibilities
            </h2>

            <p class="p-text">
              Although we have taken numerous steps to ensure the privacy and
              security of Personal Information we hold on your behalf, your use
              of the Services must also be in accordance with prevailing
              security practices. These practices include, but are not
              necessarily limited to, (i) securely configuring your accounts
              using federated identity management or strong and unique passwords
              and not sharing your authentication information, (ii) avoiding the
              upload of unnecessary Personal Information into the Service, (iii)
              exercising oversight to ensure your Educator and Student Users are
              using the Service appropriately, (iv) training and educating your
              Users on the importance of privacy and security; and (v) limiting
              information sharing by allowing Users to access only the
              information that they need.
            </p>
          </div>
          <div class="color-default text-default parbase section text">
            <h2 class="section-header">
              <a name="miscellaneous"></a>H. Miscellaneous
            </h2>

            <h3 class="para-title">External Content</h3>

            <p class="p-text">
              Certain Services we offer may link, or provide Users with the
              ability to link, to external content such as online videos or news
              articles. Educator Users often make these sites available as
              reading materials for Student User assignments or exercises. We
              urge our Customers and Educator Users to exercise caution by
              evaluating the privacy practices of external sites you are linking
              to, particularly those that collect Personal Information from your
              Student Users.
            </p>

            <p class="p-text">
              This Privacy Policy does not apply to any non-Company site, as we
              are unable to control the associated privacy and security
              practices. However, in the case of third-party content providers
              to which we provide any Personal Information to facilitate the
              provision of Services to you, we have evaluated such third
              parties&#39; data handling practices to ensure that they protect
              your Personal Information in accordance with this Privacy Policy.
              Should you have concerns over the privacy practices of our linked
              or integrated external content, please notify us using the contact
              information in Section B above.
            </p>

            <h3 class="para-title">Governing Terms</h3>

            <p class="p-text">
              Unless otherwise stated in the Agreement, the terms of this Policy
              shall prevail and supersede any inconsistent terms and conditions
              contained in the Agreement.
            </p>

            <h3 class="para-title">FERPA</h3>

            <p class="p-text">
              Our Services comply with all applicable provisions of the Family
              Educational Rights and Privacy Act (FERPA) (20 U.S.C. &sect;
              1232g; 34 CFR Part 99). We receive Student Data from Customers who
              are educational institutions as a &quot;school official&quot;
              under FERPA and only process Student Data for educational
              purposes. In the event we receive a subpoena or judicial order for
              the disclosure of education records, we will notify the associated
              institutional Customer(s) prior to fulfilling the request in
              accordance with FERPA. For additional information on FERPA, please
              visit the U.S. Department of Education&#39;s Privacy Technical
              Assistance Center.
            </p>

            <h3 class="para-title">COPPA</h3>

            <p class="p-text">
              Our Services comply with all applicable provisions of the
              Children&#39;s Online Privacy Protection Act (COPPA) (15 USC 6501
              et seq.) To the extent COPPA applies to information we collect, we
              process information for educational purposes only, at the
              direction of the partnering Customer. For additional information
              on COPPA and educational institution consent, please refer to the
              Federal Trade Commission&#39;s
              <cel-text-link
               [attr.data-link-href]="FAQURL"
               [label]="'Complying with COPPA: Frequently Asked Questions'"
               [attr.data-open-in-new-tab]="true"
               [attr.data-role]="false"
            >
            </cel-text-link>.
              Savvas is a proud member of the
              <cel-text-link
              [attr.data-link-href]="iKeepSafeURL"
              [label]="'iKeepSafe COPPA Safe Harbor Certification Program'"
              [attr.data-open-in-new-tab]="true"
              [attr.data-role]="false"
           ></cel-text-link>.
              Should customers have any questions or concerns regarding our
              COPPA policies and practices, contact COPPAPrivacy@ikeepsafe.org.
            </p>
          </div>

          <h3 class="para-title">International Data Protection Compliance</h3>

          <p class="p-text">
            Our products are designed and available for use by customers worldwide
            and comply with international data protection laws, including but not limited to, 
            the General Data Protection Regulation (GDPR) and the Personal Information Protection 
            and Electronic Documents Act (PIPEDA). Our customers’ data is stored and processed in 
            secure data centers located in the continental United States and will be accessible by 
            authorized employees, agents and contractors of the Company and its subsidiaries and authorized vendors. 
            Due to the global nature of the Site, personally identifiable information may be transmitted, 
            stored or processed in a country other than the one in which you reside (“Resident Jurisdiction”). 
            Reasonable contractual or other measures we may take to protect your personally identifiable 
            information while outside the Resident Jurisdiction are subject to applicable foreign 
            legal requirements, for example, lawful requirements by governmental authorities or court order 
            to disclose personally identifiable information to government authorities in the countries in which 
            such data may be stored or processed. SUBJECT TO APPLICABLE LAW, IF YOU RESIDE IN THE EUROPEAN UNION, 
            CANADA OR OTHER JURISDICTION WITH SIMILAR DATA TRANSFER REGULATIONS, PERSONALLY IDENTIFIABLE INFORMATION 
            PROVIDED OR COLLECTED MAY BE TRANSFERRED AND STORED IN THE UNITED STATES, IN CONNECTION WITH THE TERMS OF USE, 
            THIS PRIVACY POLICY, AND IN ORDER TO PROVIDE SERVICES TO OUR CUSTOMERS.
          </p>
          <div
            name="definitions"
            class="color-default text-default parbase section text"
          >
            <h2 class="section-header"><a></a>I. Definitions</h2>

            <p class="p-text">
              Capitalized terms not defined in this section are defined by
              applicable law when a citation is present.
            </p>

            <ul>
              <li>
                &quot;Agreement&quot; means the terms of use or other user
                agreement for the applicable Services, along with any agreement
                that may have been executed between the Company and the Customer
                with respect thereto.
              </li>
              <li>
                &quot;Data Handling Practices&quot; means Company&#39;s
                practices related to the collection, protection, use or
                disclosure of Personal Information.
              </li>
              <li>
                &quot;Educator Data&quot; means the personally identifiable
                information of Users of the Service who are not students, such
                as teachers or School administrators.
              </li>
              <li>
                &quot;Educator User&quot; means a User of the Service who is not
                a student, such as a school administrator, faculty member, board
                member, school employee, school agent or representative.
              </li>
              <li>
                &quot;Personal Information&quot; means the personally
                identifiable information of Users of the Service.
              </li>
              <li>
                &quot;Student Data&quot; means the personally identifiable
                information received about Student Users of the Service.
              </li>
              <li>
                &quot;Student User&quot; means a User of the Service who is a
                student at an educational institution who has purchased and is
                using a Service.
              </li>
              <li>
                &quot;Usage Data&quot; means data gathered about Users&#39;
                activity on the site, which may be collected through the use of
                system or application logs, cookies, mobile device identifiers,
                IP addresses and other industry-accepted technology.
              </li>
              <li>
                &quot;User&quot; means an authenticated Authorized User of our
                U.S. K-12 online websites, applications, products and services.
              </li>
            </ul>
          </div>
          <div class="color-default text-default parbase section text">
            <h2 class="section-header">
              <a name="californiaConsumerProtectionAct"></a>J. California
              Privacy Rights Act (CPRA)
            </h2>
            <p class="p-text">
              The Services we provide to our Customers comply with all
              applicable provisions of the California Privacy Rights Act
              (CPRA) (&sect;1798.100 et seq.) The information we collect is
              solely for the delivery of our Services and is not sold to any
              third parties. It is our objective to ensure any Personally
              Identifiable Information (PII) we have about our customers is
              accurate and necessary to provide our Services
            </p>

            <p class="p-text">
              To the extent CPRA applies to information we collect about
              individual California consumers, it is your right under CPRA to,
              access, rectify, object to, request erasure, export or choose how
              we process any PII collected for the delivery of our services at
              any time. If you are a student or parent of a student at an
              educational institution using our products and wish to exercise
              any of these rights, please direct any requests to the appropriate
              representative at your educational institution. For any other
              Customers to whom we have sold our products to directly (not an
              educational institution), you can exercise your rights to pose
              questions, comments, or concerns by emailing our Data Privacy
              Office at privacy@savvas.com.
            </p>
          </div>
        </div>

        <!-- col 8 end -->
      </div>

      <!-- TEXT BOX -->

      <div class="col-sm-4 col-xs-12 effective-date">
        <div class="section function-panel"></div>
        <div class="color-default section text text-info-box">
          <div class="test">
            <h3 class="effective-date-title">Policy Effective Date</h3>
          </div>
          <p class="another">June 13, 2024</p>
        </div>
      </div>
    </div>
  </div>
</main>

<div
  *ngIf="isShow"
  role="link"
  tabindex="0"
  (click)="gotoTop()"
  (keydown)="gotoTop()"
  class="back-to-top__container is-visible"
>
  <a
    ><img
      src="../../../../../assets/ic_chevron_white.svg"
      class="up-arrow"
      aria-hidden="true"
    /><span class="top">TOP</span></a
  >
</div>
<div class="footerbar">
  <span
    >Copyright &copy; {{currentYear}} Savvas Learning Company LLC. All rights reserved.
    Savvas and Savvas logo are registered trademarks of Savvas Learning Company
    LLC.</span
  >
</div>
