import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    constructor(private window: Window, private router: Router) {}

    redirectBrowserTo(url: string, newTab?: boolean): void {
        this.window.location.href = url;
    }

    openInNewTab(url: string): void {
        this.window.open(url, '_blank');
    }

    navigateToRoute(route: string, queryParams?: any) {
        this.router.navigate([route], { queryParams: queryParams });
    }

    isCanadaUrl():boolean {
        const hostname = this.getHostname();
        return hostname.includes('savvascanada');
    }

    getHostname():string {
        return this.window.location.hostname.toLowerCase();
    }
}
