import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DemoPageComponent } from './pages/demo-page/demo-page.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { SuccessPageComponent } from './pages/success-page/success-page.component';
import { WhitelistComponent } from './pages/whitelist/whitelist.component';
import { PrivacyPolicyComponent } from './shared/components/footer/privacy-policy/privacy-policy.component';
import { UserAgreementComponent } from './shared/components/footer/user-agreement/user-agreement.component';
import { DemoSignupGuard } from './shared/guards/demo-signup.guard';

const routes: Routes = [
    { path: '', component: LandingPageComponent },
    {
        path: 'signup',
        children: [
            { path: '', component: DemoPageComponent },
            { path: 'confirm', component: SuccessPageComponent, canActivate: [DemoSignupGuard] },
        ],
    },
    { path: 'whitelist', component: WhitelistComponent },
    { path: 'whitelist.html', redirectTo: 'whitelist', pathMatch: 'full' },
    { path: 'userAgreement', component: UserAgreementComponent },
    //{ path: 'userAgreement.html', redirectTo: 'userAgreement', pathMatch: 'full' },
    { path: 'privacy', component: PrivacyPolicyComponent },
    /*{
        path: 'privacy/corporate/privacy/learning-services-privacy-policy.html',
        redirectTo: 'privacy',
        pathMatch: 'full',
    },*/
    { path: 'error', component: ErrorPageComponent },
    { path: '**', redirectTo: '', component: LandingPageComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true, anchorScrolling: 'enabled' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
