import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { SubOrganizations } from 'src/app/core/models/sub-organization.model';
import { ButtonLabels } from '../card-dark.constants';
import { PasswordValidator } from './validators/password-validator';
import { REGEX_PATTERNS } from './validators/regex-pattern-validator';
import { DropdownItem } from 'src/app/core/models/dropdown.model';
import { DemoAccountService } from 'src/app/services/demo-account.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { RouteConstants } from 'src/app/constants/route.constants';
import { CreateDemoAccountGQL, GetChildTreeForOrgIdGQL, CreateDemoUserGQL, GetSelfRegOrgsGQL } from 'src/app/core/graphql/generated/graphql';
import { USER, DEMO_ACCOUNT_COUNTRY, DEMO_ACCOUNT_OTHERS, ErrorMessages, PasswordRules } from './demo-form.constants';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-demo-form',
    templateUrl: './demo-form.component.html',
    styleUrls: ['./demo-form.component.scss'],
})
export class DemoFormComponent implements OnInit {
    @Output() private onFormGroupChange = new EventEmitter<FormGroup>();
    @ViewChild('organizationSelect') organizationSelectElement: ElementRef;
    @ViewChild('emailElem') emailElem: ElementRef;
    @ViewChild('pasword') pasword: ElementRef;
    @ViewChild('org') org: ElementRef;
    hasAcceptedTerms: boolean = false;
    dropdownOrganizations: DropdownItem[] = [];
    demoForm: FormGroup;
    createDemoAccountButtonLabel: string;
    emailErrorMessage: string;
    passwordErrorMessage: string;
    passwordRules = PasswordRules;
    orgError = false;
    emailError = false;
    passwordError = false;
    linkClicked = false
    orgStyle: HTMLStyleElement;
    constructor(
        private demoAccountService: DemoAccountService,
        private navigationService: NavigationService,
        private getSelfRegOrgsService: GetSelfRegOrgsGQL,
        private getChildTreeForOrgIdService: GetChildTreeForOrgIdGQL,
        private createDemoAccountService: CreateDemoAccountGQL,
        private createDemoUserService: CreateDemoUserGQL,
    ) {}

    ngOnInit(): void {
        this.orgStyle = document.createElement('style');
        this.orgStyle.textContent = '.single-item--selected { background-color: #cdcdcd !important; }';
        this.demoAccountService.termsCheck$.subscribe (response => this.linkClicked= response)

        this.emailErrorMessage = ErrorMessages.INVALID_EMAIL;
        this.passwordErrorMessage = ErrorMessages.INVALID_PASSWORD;
        this.createDemoAccountButtonLabel = ButtonLabels.CREATE_DEMO_ACCOUNT;
        this.demoForm = this.createDemoForm();
        this.getOrganizationsFromChildTree();

        this.demoForm.valueChanges.subscribe((value) => {
            this.onFormGroupChange.emit(this.demoForm);
        });
        this.demoAccountService.recaptchaResponse$.subscribe((response) => {
            this.demoForm.controls['recaptcha_response'].setValue(response);
        });
        this.demoAccountService.termsAndConditions$.subscribe((response) => {
            this.hasAcceptedTerms = response;
        });

        if (this.isMobileSafari()) {
            setTimeout( () => {
                this.org?.nativeElement.setAttribute("tabindex","0");
            }, 100);
        }
    }

    newTerms(){
        this.demoAccountService.setTermsCheck(true)
    }

    createDemoForm(): FormGroup {
        return new FormGroup({
            email: new FormControl('', [
                Validators.required,
                Validators.email,
                Validators.pattern(REGEX_PATTERNS.emailPattern),
            ]),
            password: new FormControl('', [
                Validators.required,
                Validators.minLength(8),
                Validators.pattern(REGEX_PATTERNS.passwordPattern),
                PasswordValidator.alphabetic,
                PasswordValidator.noSpaces,
            ]),
            orgId: new FormControl('', [Validators.required]),
            recaptcha_response: new FormControl('', [Validators.required]),
        });
    }

    onSubmit(): void {
        if (this.demoForm.invalid || !this.hasAcceptedTerms) {
            this.emailError = this.isEmailError();
            this.passwordError = this.isPasswordError();
            this.handleOrgBlur();
            this.newTerms();
            return; // button is disabled...
        }
        this.createDemoUserService.mutate({ userDetails: this.demoForm?.value }).subscribe(
            (res) => {
                const userId = res.data?.createDemoUser?.userId;
                const userName = res.data?.createDemoUser?.userName;
                if (userId && userName) {
                    sessionStorage.setItem('userId', userId);
                    sessionStorage.setItem('userName', userName);
                    this.navigationService.navigateToRoute(RouteConstants.SUCCESS_PAGE);
                } else {
                    this.navigationService.navigateToRoute(RouteConstants.ERROR_PAGE);
                }
            },
            (catchError) => {
                this.navigationService.navigateToRoute(RouteConstants.ERROR_PAGE);
            }
        );
    }
    getControl(controlName: string) {
        return this.demoForm.controls[controlName];
    }
    getOrganizationsFromChildTree(): void {
        this.getChildTreeForOrgIdService.fetch({orgId: environment.orgId}).subscribe((result) => {
            this.dropdownOrganizations = this.setChildOrganizations(result?.data?.getChildTreeForOrgId?.subOrganizations);
        });
    }
    setChildOrganizations(organizations: SubOrganizations[]): DropdownItem[] {
        let canada = false;
        const subOrganizationsList = [...organizations];
        const otherRegionList: DropdownItem[] = [];
        const canadaRegionList: DropdownItem[] = [];
        subOrganizationsList.sort((a,b) => a?.displayName.localeCompare(b?.displayName));
        subOrganizationsList.forEach((org) => {
            if (!(org?.displayName?.toLowerCase()?.includes(DEMO_ACCOUNT_OTHERS.DISTRICT))) {
                if (org?.country === DEMO_ACCOUNT_COUNTRY.US) {
                    otherRegionList.push({
                        label: org?.displayName,
                        identifier: org?.organizationId,
                        disabled: false
                    })
                } else if(org?.country === DEMO_ACCOUNT_COUNTRY.CA) {
                    if (!canada) {
                        canadaRegionList.push({
                            label: DEMO_ACCOUNT_OTHERS.CA_LABEL,
                            identifier: DEMO_ACCOUNT_OTHERS.CA_IDENTIFIER,
                            disabled: true
                        })
                        canada = true;
                    }
                    canadaRegionList.push({
                        label: org?.displayName,
                        identifier: org?.organizationId,
                        disabled: false
                    })
                }
            }
        });
        canadaRegionList.forEach((entry) => {
            otherRegionList.push({
                label: entry?.label,
                identifier: entry?.identifier,
                disabled: entry?.disabled
            })
        });
        return otherRegionList;
    }
    selectOrganization(event: Event) {
        const org = (<CustomEvent>event).detail.identifier;
        this.demoForm.controls['orgId'].setValue(org);
    }

    get email(): AbstractControl | null {
        sessionStorage.setItem(USER.USER_EMAIL_ADDRESS, this.demoForm.get('email')?.value);
        return this.demoForm.get('email');
    }

    get password(): AbstractControl | null {
        return this.demoForm.get('password');
    }

    get organization(): AbstractControl | null {
        return this.demoForm.get('organization');
    }

    handleEmailBlur(): void {
        this.emailError = this.isEmailError();
        setTimeout(() => {
            const errorElement = this.emailElem?.nativeElement?.shadowRoot?.querySelector('.error-container');
            const isRoleExists = errorElement?.getAttribute('role');
            if (this.email?.invalid && isRoleExists == null) {
                errorElement?.setAttribute('role', 'alert');
                errorElement?.setAttribute('aria-live', 'true');
            }
        }, 5);
    }

    handlePaswordBlur(): void {
        this.passwordError = this.isPasswordError();
        setTimeout(() => {
            const errorElement = this.pasword?.nativeElement?.shadowRoot?.querySelector('.error-container');
            const isRoleExists = errorElement?.getAttribute('role');
            if (this.password?.invalid && isRoleExists == null) {
                errorElement?.setAttribute('role', 'alert');
                errorElement?.setAttribute('aria-live', 'true');
            }
        }, 5);
    }
    handleOrgBlur(): void {
        this.orgError = false;
        setTimeout(() => {
            this.orgError = this.demoForm.get('orgId')?.value?.length > 0 ? false : true;
        }, 200);
    }
    handleOrgClick(): void {
        setTimeout(() => {
            const selectedOrgElem = this.org?.nativeElement?.shadowRoot?.querySelector(
                '[data-identifier="' + this.demoForm.get('orgId')?.value + '"]'
            );
            if (selectedOrgElem) {
                selectedOrgElem?.shadowRoot?.appendChild(this.orgStyle);
            }
        }, 0);
    }

    isMobileSafari(): boolean {
        return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }

    isEmailError(): boolean {
        return this.email?.invalid? true : false;
    }

    isPasswordError(): boolean {
        return this.password?.invalid? true : false;
    }
}
