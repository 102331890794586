<footer
  *ngIf="!errorPage && !footerPage"
  [ngClass]="{ 'demo-footer': demoPage }"
>
  <div class="logo">
    <a
      href="{{ savvasUrl }}"
      target="_blank"
      [attr.aria-label]="'Savvas' + linkScreenReaderText"
    >
      <cel-icon
        class="savvas-logo"
        data-aria-label="Savvas Logo"
        data-src="../../../assets/logo/logo_savvas_dark.svg"
      >
      </cel-icon>
    </a>
  </div>

  <div class="footer-links">
    <p class="footer-text" [attr.data-aria-label]="copyrightText">
      {{ copyrightText }}
    </p>
    <p class="footer-text">
      <cel-text-link
        [attr.data-link-href]="savvasUrl"
        [label]="savvasLearningCompany"
        [attr.data-open-in-new-tab]="true"
      ></cel-text-link>
    </p>
    <p class="footer-text">{{ llc }}</p>
    <p class="footer-text rights">{{ allRightsReserved }} </p>
    <ul class="footer-ul">
      <li>
        <p class="footer-text">
          <cel-text-link
            (click)="onUserAgreementLinkClick()"
            [label]="userAgreement"
          ></cel-text-link>
        </p>
      </li>
      <span class="separtor" aria-hidden="true">|</span>
      <li>
        <p class="footer-text">
          <cel-text-link
            (click)="onPrivacyPolicyLinkClick()"
            [label]="privacyPolicy"
          ></cel-text-link>
        </p>
      </li>
      <span class="separtor" aria-hidden="true">|</span>
      <li>
        <p class="footer-text">
            <cel-text-link
              [attr.data-link-href]="creditsUrl"
              [label]="credits"
              [attr.data-open-in-new-tab]="true"
            ></cel-text-link>
        </p>
      </li>
    </ul>
  </div>
</footer>
