import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardLightComponent } from './card-light/card-light.component';
import { CardDarkComponent } from './card-dark/card-dark.component';
import { FloatCardComponent } from './float-card.component';
import { DemoFormComponent } from './card-dark/demo-form/demo-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { ConsentModalComponent } from './card-light/consent-modal/consent-modal.component';
@NgModule({
    declarations: [CardLightComponent, CardDarkComponent, FloatCardComponent, DemoFormComponent, ConsentModalComponent],
    imports: [CommonModule, FormsModule, ReactiveFormsModule, RecaptchaFormsModule, RecaptchaModule],
    exports: [CardLightComponent, CardDarkComponent, FloatCardComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FloatCardModule {}
