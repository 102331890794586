import { Injectable } from '@angular/core';
import { WHITELIST_URL_CONSTANTS } from "../pages/whitelist/whitelist-url.constant";
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class WhitelistService {
    hasErrors: boolean;
    productResults:any;
    domainResults: any;
    componentLoading: boolean;
    productsDetail: any = WHITELIST_URL_CONSTANTS;
    domainResult1: any;

    constructor(private http: HttpClient) {
    }

    validateThatURLsHaveBeenWhitelisted() {
        return new Promise(async (resolve, reject) => {
            this.clearPreviousTestingResults();
            const post: any = await this.runValidationForAllAutoTestableDomains();
            if (post) {
                const productResults: any = this.buildProductSpecificResults();
                this.componentLoading = false;
                resolve(productResults);
            } else {
                this.componentLoading = false;
                reject(Error("A catastrophic error occurred while running validations"));
            }
        });
    }

    buildProductSpecificResults(): any {
        for (let productIndex = 0; productIndex < this.productsDetail.PRODUCTS.length; productIndex = productIndex + 1) {
            var product = this.productsDetail.PRODUCTS[productIndex];
            var productResult = this.createEmptyProductResult(product.name);
            for (let domainIndex = 0; domainIndex < product.domains.length; domainIndex = domainIndex + 1) {
                var domainKey = product.domains[domainIndex];
                var autoResultForDomain = this.domainResults[domainKey];
                if (autoResultForDomain) {
                    this.addAutoResultProductResult(productResult, autoResultForDomain);
                } else if (this.isImageDomain(domainKey)) {
                    this.addImageDomainToProductResult(productResult, domainKey);
                } else if (this.isManualDomain(domainKey)) {
                    this.addManualDomainToProductResult(productResult, domainKey);
                }
            }
            this.productResults[productIndex] = productResult;
        }
        return this.productResults;
    }

    addManualDomainToProductResult(productResult: any, domainKey: any) {
        var nextIndex = productResult.manualDomains.length;
        productResult.manualDomains[nextIndex] = this.productsDetail.DOMAINS[domainKey];
        productResult.hasManualDomains = true;
    }

    isImageDomain(domainKey: string) {
        return this.productsDetail.DOMAINS[domainKey].testStrategy === 'IMAGE';
    }

    isManualDomain(domainKey: string) {
        return this.productsDetail.DOMAINS[domainKey].testStrategy === 'MANUAL';
    }

    addImageDomainToProductResult(productResult: any, domainKey: any) {
        var nextIndex = productResult.imageDomains.length;
        productResult.imageDomains[nextIndex] = this.productsDetail.DOMAINS[domainKey];
        productResult.hasImageDomains = true;
    }

    addAutoResultProductResult(productResult: any, autoResultForDomain: any) {
        var nextIndex = productResult.autoResults.length;
        productResult.autoResults[nextIndex] = autoResultForDomain;
        productResult.hasAutoResults = true;
    }

    createEmptyProductResult(productName: string) {
        return {
            name: productName,
            hasAutoResults: false,
            autoResults: [],
            hasImageDomains: false,
            imageDomains: [],
            hasManualDomains: false,
            manualDomains: [],
        }
    }

    clearPreviousTestingResults() {
        this.hasErrors = false,
            this.productResults = [],
            this.domainResults = []
    }

    async runValidationForAllAutoTestableDomains() {
        return new Promise(async (resolve, reject) => {
            var domainValidationPromises = [];
            var startTime = new Date().getTime();
            for (let domainKey in this.productsDetail.DOMAINS) {
                let domainToTestObj: any = this.productsDetail.DOMAINS[domainKey];
                if (domainToTestObj.testStrategy === 'AUTO') {
                    const domainValidationPromise: any = await this.getTestingResultForDomain(domainToTestObj, startTime, domainKey);
                    domainValidationPromises.push(domainValidationPromise);
                }
            }
            resolve(domainValidationPromises);
        });
    }

    getTestingResultForDomain(domainToTestObj: any, startTime: any, domainKey: string) {
        var modifiedUrlToPing = this.getNonCachedUrl(domainToTestObj.urlToTest);
        return new Promise((resolve, reject) => {
            this.http.get(modifiedUrlToPing, { responseType: 'text' }).subscribe((data) => {
                var calculatedResponseTime = (new Date().getTime()) - startTime;
                const domainResult = this.buildDomainResult(domainToTestObj, 200, calculatedResponseTime, true);
                this.domainResults[domainKey] = domainResult;
                resolve(domainResult);
            }, (erroredResponse) => {
                var calculatedResponseTime = (new Date().getTime()) - startTime;
                const domainResult = this.buildDomainResult(domainToTestObj, erroredResponse.status, calculatedResponseTime, false);
                this.adjustSuccessFlagBasedOnErrorStatus(domainResult);
                this.domainResults[domainKey] = domainResult;
                if (!domainResult.success) {
                    this.hasErrors = true;
                }

                console.log('A catastrophic error occurred while running getUrlTestingResultForUrl');
                resolve(domainResult);
            });
        });
    }


    adjustSuccessFlagBasedOnErrorStatus(domainResult: any) {
        if (this.isAuthenticationError(domainResult.responseStatus))
            domainResult.success = true;
    }

    isAuthenticationError(responseStatus: any) {
        if (responseStatus === 401)
            return true;
        return false;
    }

    buildDomainResult(domainToTestObj: any, responseStatus: any, calculatedResponseTime: number, succeeded: boolean): any {
        return {
            testedUrl: domainToTestObj.urlToTest,
            domain: domainToTestObj.domain,
            responseStatus: responseStatus,
            responseTime: calculatedResponseTime,
            success: succeeded,
        }
    }

    getNonCachedUrl(urlToPing: string): string {
        var url = new URL(urlToPing);
        // var DateTime: any = new Date().getTime();
        // url.searchParams.set('rnd', DateTime);
        return url.href;
    }

}