export const CardLightConstants = {
    PRELOGIN: {
        SLOGAN: 'It Starts with You',
        SUBHEADING: 'A world of engaging educational content and activities awaits.',
        SIGN_IN: 'Sign in',
        REGISTER: 'Register today'
    },
    DEMO: {
        TITLE: 'Set up a free demo account.',
        INSTRUCTIONS: 'Before we get started, read and agree to the conditions below.',
        DEMO_ACCOUNT_CONDITIONS: 'Conditions of Demo Account Access',
        AGREEMENT: {
            INSTRUCTIONS: 'Launch and agree to the Conditions of Demo Account Access below.',
            LAUNCH: 'Click here to read and agree to Conditions of Demo Account Access',
            DIALOG: {
                TITLE: 'Conditions of Demo Account Access',
                BODY: {
                    OVERVIEW: {
                        HEADER: 'Overview',
                        TEXT: 'Registration for a Realize demo account is intended for teachers to sample programs and gain limited access to features such as Browse, Classes, and Data. Content provided for demo accounts does not include complete courses, but provides an opportunity to sample features in Realize. Data created using your demo account is not associated with your specific organization and cannot be transferred.'
                    },
                    RESTRICTIONS: {
                        HEADER: 'Restrictions',
                        TEXT: 'Demo accounts are for teacher use only. Students are not eligible to register for a demo account.'
                    },
                    MISUSE: {
                        HEADER: 'Account Termination / Misuse',
                        TEXT: 'Demo accounts expire after 30 days and are then deleted.'
                    },
                    WARNING: 'Demo accounts are periodically reviewed to ensure they are used by teachers only. Such accounts are subject to termination in the event of any breach of the above terms or other misuse. In addition, we reserve the right to take other appropriate action with respect to any use of demo accounts not in compliance with the above.',
                    CERTIFY1: 'I certify I am an educator evaluating this product for my school district.',
                    CERTIFY2: 'I accept the Conditions of Demo Account Access above.'
                },
                OK: 'OK',
                CANCEL: 'Close'
            }
        }
    },
};
