<div class="whitelistSpacer">
    <div class="whitelistUrlView">
        <h3>DOMAIN ACCESSIBILITY</h3>
        <p class="desc">
            {{domainLabel}}
        </p>
        <br />
        <div class="tab">
            <ul class="tab1" *ngFor="let whitelist of productResults"
                [class.active-border-selection]="productName == whitelist.name">
                <li id="{{whitelist.name}}" (click)="tabChange(whitelist.name)">{{whitelist.name}}</li>
            </ul>

            <div class="content">
                <div *ngFor="let whitelist of productResults">
                    <div class="content-value" *ngIf="productName === whitelist.name">
                        <div *ngIf="whitelist.hasAutoResults === true">
                            <p>
                                {{autoResultLabel}}
                            </p>
                            <table class="whitelistTable">
                                <thead>
                                    <tr class="whitelistTableHeaderRow">
                                        <th class="whitelistTableHeader">DOMAIN</th>
                                        <th class="whitelistTableHeader">ELAPSED TIME</th>
                                        <th class="whitelistTableHeader">STATUS</th>
                                    </tr>
                                </thead>
                                <tbody *ngFor="let urlValidationResult of whitelist.autoResults">
                                    <tr class="whitelistTableRow">
                                        <td class="whitelistTableCell">{{urlValidationResult?.domain}}</td>
                                        <td class="whitelistTableCell">{{urlValidationResult?.responseTime}} ms</td>
                                        <td class="whitelistTableCell">{{urlValidationResult?.responseStatus}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                        </div>
                        <div *ngIf="whitelist.hasImageDomains === true">
                            <p>
                                {{imageDomainLabel}}
                            </p>
                            <table class="whitelistTable">
                                <thead>
                                    <tr class="whitelistTableHeaderRow">
                                        <th class="whitelistTableHeader">DOMAIN</th>
                                        <th class="whitelistTableHeader">IMAGE</th>
                                    </tr>
                                </thead>
                                <tbody *ngFor="let urlValidationResult of whitelist.imageDomains">
                                    <tr class="whitelistTableRow">
                                        <td class="whitelistTableCell"> {{urlValidationResult?.domain}}</td>
                                        <td class="whitelistTableImageCell"><img class="whitelistImage"
                                                src="{{urlValidationResult?.urlToTest}}"></td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                        </div>

                        <div *ngIf="whitelist.hasManualDomains === true">
                            <p>
                                {{manualDomainLabel}} 
                            </p>
                            <table class="whitelistTable">
                                <thead>
                                    <tr class="whitelistTableHeaderRow">
                                        <th class="whitelistTableHeader">DOMAINS REQUIRING MANUAL TESTING</th>
                                        <th class="whitelistTableHeader">EXPECTED BEHAVIOR</th>
                                        <th class="whitelistTableHeader"></th>
                                    </tr>
                                </thead>
                                <tbody *ngFor="let urlValidationResult of whitelist.manualDomains">
                                    <tr class="whitelistTableRow">
                                        <td class="whitelistTableCell">{{urlValidationResult?.domain}}</td>
                                        <td class="whitelistTableCell">{{urlValidationResult?.expected}}</td>
                                        <td class="whitelistTableCell"><a
                                                href="{{urlValidationResult?.urlToTest}}" target="newWin">Test
                                                Domain Access</a></td>
                                    </tr>
                                </tbody>
                            </table>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <button class="btn" type="button" onClick="window.location.reload();">Validate URL Accessibility</button>
        <br />
    </div>
</div>