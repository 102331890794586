// This file should contain ALL keys used by *any* environment
// the values in this file should be that of the DEVELOPMENT environment
// other named environment files (i.e. environment.smoke.ts) should include ONLY overrides to values as necessary
// Common configs point to nightly environment

export const commonConfig = {
    production: false,
    envName: 'nightly',
    appId: 'realize-prelogin-webapp',
    savvasUrl: 'https://savvas.com/',
    realizeBaseUrl: 'https://nightly-www.savvasrealizedev.com',
    realizeLoginUrl: 'https://nightly-www.savvasrealizedev.com/dashboard/viewer',
    canadaRealizeBaseUrl:'https://realize.nightlyal2.savvascanada.com',
    canadaRealizeLoginUrl:'https://realize.nightlyal2.savvascanada.com/community',
    canadaRealizeLoginUrlal2b:'https://realize.al2b.savvascanada.com/community',
    selfRegOrgApiPath: '/community/rest/self-reg/orgs',
    selfRegUserApiPath: '/community/rest/self-reg/users',
    selfRegUserEmailApiPath: '/rest/self-reg/users/confirmations/email',
    recaptchaSiteKey: '6LdiB2wpAAAAAInZYMjHuiWhZL0W7nzs-_Wtrq4j',
    ebTSRPageUrl: 'https://self-registration-web.nightly.savvasrealizedev.com/register',
    supportPageUrl: 'https://support.savvas.com/support/s/',
    contactSupportPageUrl: 'https://support.savvas.com/support/s/contactsupport',
    welcomeGuideUrl: 'https://cloud.3dissue.com/202077/205776/241865/Na0322SavvasCustomerHandbook/index.html?r=37#',
    systemRequirementsUrl:
        'https://help.learningservicestechnology.com/realize/en/Announcements/Content/system_requirements.htm',
    mySavvasTrainingUrl: 'https://mysavvastraining.com/login',
    platformStatusUrl: 'https://status.savvas.com/',
    userAgreementUrl: '/#/userAgreement',
    privacyPolicyUrl: '/#/privacy',
    creditsUrl: 'https://media.pk12ls.com/curriculum/credits/index.html',
    preloginServiceUrl: 'https://nightly-preloginservice.rumba.pk12ls.com/graphql',
    customerHandbookUrl: 'https://cloud.3dissue.com/202077/205776/241865/Cn0322SavvasCanadaCustomerHandbook/index.html?r=35#',
    canadaTSRPageUrl:'https://cat.nightlyal2.savvascanada.com/ca/email-validate.htm#/type/self',
    canadaTSRPageUrlal2b: 'https://cat.al2b.savvascanada.com/ca/email-validate.htm#/type/self',
    orgId: '8a7201338b41d87f018b43bb4ac10122',
};
