export const CardDarkConstants = {
    FREE_DEMO_LABEL: 'It Starts with You',
    SUBHEADING: 'A world of engaging educational content and activities awaits.',
    SIGN_IN: 'Sign in',
    REGISTER: 'Register Today',
    LINK_SCREEN_READER_TEXT: '. To open in a new tab press enter.',
};

export const ButtonLabels = {
    FREE_DEMO_LABEL: 'Try a free demo',
    WELCOME_GUIDE: 'Welcome guide',
    SYSTEM_REQUIREMENTS: 'System requirements',
    CREATE_DEMO_ACCOUNT: 'Create my demo account',
    CUSTOMER_HANDBOOK: 'Customer Handbook'
};

export const ContactSupportConstants = {
    TITLE: 'Need Customer Support?',
    TRAINING_MATERIALS: 'Training Materials:',
    MY_SAVVAS_TRAINING: 'MySavvasTraining',
    SUPPORT_ARTICLES: 'Support Articles:',
    ASSESSMENT_SUPPORT: 'K12 Curriculum and Assessment Support',
    STATUS_CENTER: 'Platform Status Center',
    LEARN_MORE: 'To learn more about our products and services visit',
    LEARN_MORE_LINK: 'Savvas.com',
};
