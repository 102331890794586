import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-float-card',
    templateUrl: './float-card.component.html',
    styleUrls: ['./float-card.component.scss'],
})
export class FloatCardComponent implements OnInit {
    @Input() isDemoPage: boolean;
    constructor() {}

    ngOnInit(): void {}
}
