export const PasswordRules = {
    CASE_SENSITIVE: 'Passwords are case-sensitive',
    PASSWORD_LENGTH: 'Must be at least 8 characters long',
    CONTAIN_LETTER: 'Must contain at least one letter',
    CONTAIN_SPECIAL_CHARACTER:
        "Must contain at least one number or one special character and cannot contain the word 'demo'",
    NO_SPACES: 'No spaces',
};

export const ErrorMessages = {
    INVALID_EMAIL: 'Not a valid email address',
    INVALID_PASSWORD: 'Password does not meet requirements',
};

export const USER = {
    USER_EMAIL_ADDRESS: 'userEmailAddress',
    USER: 'userId'
};

export const DEMO_ACCOUNT_COUNTRY = {
    US: 'US',
    CA: 'CA'
};

export const DEMO_ACCOUNT_OTHERS = {
    CA_IDENTIFIER: "canadaSeparator",
    CA_LABEL: "---------------------------------------------------------------------",
    DISTRICT: "district"
};
